import Vue from "vue"
import Vuex, { createLogger } from "vuex"
import Dashboard from "./modules/Dashboard"
import Defaults from "./modules/Defaults"
import Enrollment from "./modules/Enrollment"
import Fertilizer from "./modules/Fertilizer"
import Fields from "./modules/Fields"
import Filter from "./modules/Filter"
import Map from "./modules/Map"
import Notifications from "./modules/Notifications"
import Organization from "./modules/Organization"
import Placements from "./modules/Placements"
import SoilSampling from "./modules/SoilSampling"
import Theme from "./modules/Theme"
import Uploads from "./modules/Uploads"
import User from "./modules/User"
import Evidencing from "./modules/Evidencing";

const plugins = []

const ENABLE_LOGGING = false
if (ENABLE_LOGGING) plugins.push(createLogger())

Vue.use(Vuex)
export default new Vuex.Store({
  plugins,
  modules: {
    Dashboard,
    Defaults,
    Enrollment,
    Fertilizer,
    Fields,
    Filter,
    Map,
    Notifications,
    Organization,
    Placements,
    SoilSampling,
    Theme,
    Uploads,
    User,
    Evidencing,
  },
})
