<template>
  <div>
    <Permissions package="sustainability" />
    <div v-if="showSustainability">
      <div class="row">
        <div class="col">
          <div class="d-flex mb-3">
            <DashboardTitle :title="dashboardTitle" faClass="fa-columns" />
            <v-btn
              v-if="currentWizard"
              class="ml-auto"
              text
              @click="handleReset"
            >
              <v-icon>mdi-chevron-left</v-icon>
              Return To Practices</v-btn
            >
            <div v-else class="ml-auto">
              <WizardToolBar />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <!-- Landing View -->
          <div v-if="showIntro" class="testing px-2 py-2">
            <v-row>
              <!-- Subwizard Selection Column -->
              <v-col cols="5">
                <v-row>
                  <v-col>
                    <v-card elevation="0">
                      <v-btn block x-large @click="handleGoToWizard('planting')"
                        >Planting<v-icon> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-card>
                    <v-card class="mt-3" elevation="0">
                      <v-btn
                        block
                        x-large
                        @click="handleGoToWizard('fertilizer')"
                        >Fertilizer<v-icon> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-card>
                    <v-card class="mt-3" elevation="0">
                      <v-btn
                        block
                        x-large
                        @click="handleGoToWizard('protection')"
                        >Crop Protection<v-icon>
                          mdi-chevron-right
                        </v-icon></v-btn
                      >
                    </v-card>
                    <v-card class="mt-3" elevation="0">
                      <v-btn
                        block
                        x-large
                        @click="handleGoToWizard('covercrop')"
                        >Cover Cropping<v-icon>
                          mdi-chevron-right
                        </v-icon></v-btn
                      >
                    </v-card>
                    <v-card v-if="featureFlags?.soil_sampling_wizard" class="mt-3" elevation="0">
                      <v-btn
                        block
                        x-large
                        @click="handleGoToWizard('Soil Sampling')"
                        >Soil Sampling<v-icon>
                          mdi-chevron-right
                        </v-icon></v-btn
                      >
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card elevation="0">
                      <v-btn block x-large @click="handleGoToWizard('Harvest')">
                        Harvest<v-icon> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-card>
                    <v-card class="mt-3" elevation="0">
                      <v-btn block x-large @click="handleGoToWizard('tillage')"
                        >Tillage<v-icon> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-card>
                    <v-card class="mt-3" elevation="0">
                      <v-btn block x-large @click="handleGoToWizard('liming')"
                        >Liming<v-icon> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-card>
                    <v-card class="mt-3" elevation="0">
                      <v-btn
                        block
                        x-large
                        @click="handleGoToWizard('irrigation')"
                        >Irrigation<v-icon> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-card>
                    <v-card class="mt-3" elevation="0">
                      <v-btn
                        block
                        x-large
                        @click="handleGoToWizard('Conservation Programs')"
                        >Conservation Programs<v-icon> mdi-chevron-right </v-icon></v-btn
                      >
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Data Completion & Credits Column -->
              <v-col cols="6">
                <v-card>
                  <v-card-title>
                    <span
                      >Yearly Carbon Credit Potential ({{
                        this.selectedFields.length
                      }}
                      Field{{
                        this.selectedFields.length == 1 ? "" : "s"
                      }})</span
                    >
                    <span class="ml-auto">
                      <v-chip color="green" text-color="white"
                        >{{ totalCreditAmount | currency }}
                      </v-chip>
                    </span>
                  </v-card-title>
                </v-card>
                <v-card class="mt-4">
                  <v-card-title>
                    Sustainability Data Completion for {{ year }}
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"
                        ><v-icon v-bind="attrs" v-on="on"
                          >mdi-information</v-icon
                        ></template
                      ><span
                        >Shows the amount of management practice data (data
                        completion) for each regenerative practice as a function
                        of the number of fields selected from the top of page
                        Corporation/Client/Farm/Field navigation bar.</span
                      ></v-tooltip
                    >
                  </v-card-title>
                  <v-card-text>
                    <div
                      v-if="showDataCompleteLoading"
                      class="loading-data-complete text-center"
                    >
                      <v-progress-circular
                        :size="70"
                        :width="8"
                        color="green"
                        indeterminate
                      ></v-progress-circular>
                      <div>
                        <h4>Loading Data Completion</h4>
                      </div>
                    </div>

                    <apexchart
                      v-if="dataCompletionMaster && !showDataCompleteLoading"
                      height="350"
                      :options="chartOptions"
                      :series="chartSeries"
                    /> </v-card-text
                ></v-card>
              </v-col>
            </v-row>
          </div>
          <!-- Loading View -->
          <div v-if="showLoading" class="text-center py-3">
            <v-progress-circular
              :size="70"
              :width="8"
              color="green"
              indeterminate
            ></v-progress-circular>
            <div>
              <h3>{{ loadingText }}</h3>
              <h4 v-if="selectedFields.length > 50">
                Please be patient when loading many fields - this may take a
                couple minutes.
              </h4>
            </div>
          </div>
          <!-- Load Error View -->
          <div v-if="loadError" class="text-center py-3">
            <div>
              <div class="mb-5" v-if="showFetchButton">
                <h5>
                  Your request may take some time as Arva aggregates your field
                  informations. It may help to reduce the number of fields in
                  your selection to assign historical practices by farm or
                  client.
                </h5>
                <v-btn @click="continueWizardLoadingRequest">
                  Continue Loading?
                </v-btn>
              </div>
              <div v-else>
                <h3>
                  Uh oh! Something went wrong when trying to process your
                  request.
                </h3>
                <h4>{{ errorText }}</h4>
              </div>
            </div>
            <div class="d-flex justify-space-around">
              <v-btn
                @click="
                  turnOffEverything()
                  showIntro = true
                "
              >
                Back to Home
              </v-btn>
            </div>
          </div>
          <!-- Wizard Views -->
          <!-- disabled -->
          <!-- <InitialWizard
            v-if="showInitialWizard"
            @complete-wizard="handleInitialComplete"
          /> -->
          <!-- enabled -->
          <NewCoverCropWizard
            v-if="showCoverCropWizard"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            @show-load-step="handleLoadCompleteNew"
            @update-data-complete="refreshOnSave"
            :dataCompletionMaster="dataCompletionMaster"
          />
          <!-- enabled -->
          <NewTillageWizard
            v-if="showTillageWizard"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            @update-data-complete="refreshOnSave"
            :dataCompletionMaster="dataCompletionMaster"
          />
          <!-- enabled -->
          <NewIrrigationWizard
            v-if="showIrrigationWizard"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            @update-data-complete="refreshOnSave"
            :dataCompletionMaster="dataCompletionMaster"
          />
          <!-- disabled -->
          <!-- <NitrogenReductionWizard
            v-if="showReductionWizard"
            :carbonSettingsByField="carbonSettingsByField"
            :isWizardShowing="showReductionWizard"
            :year="year"
            @submit-reduction="handleSubmitReduction"
            @visited-reduction="handleProgressUpdate('reduction')"
            @completed-reduction="handleProgressComplete('reduction')"
            :wizardProgress="wizardProgress"
          /> -->
          <!-- enabled -->
          <NewFertilizerWizard
            v-if="showFertilizerWizard"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            @update-data-complete="refreshOnSave"
            :dataCompletionMaster="dataCompletionMaster"
          />
          <!-- enabled -->
          <ProtectionWizard
            v-if="showProtectionWizard"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            :dataCompletionMaster="dataCompletionMaster"
            @update-data-complete="refreshOnSave"
          />
          <!-- enabled -->
          <NewLimingWizard
            v-if="showLimingWizard"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            :dataCompletionMaster="dataCompletionMaster"
            @update-data-complete="refreshOnSave"
          />
          <NewPlantingWizard
            v-if="showPlantingWizard"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            :dataCompletionMaster="dataCompletionMaster"
            @update-data-complete="refreshOnSave"
          />
          <ConservationProgramWizard
            v-if="currentWizard == 'Conservation Programs'"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            @update-data-complete="refreshOnSave"
          />
          <!-- <HarvestWizardTable
            v-if="currentWizard == 'Harvest'"
            :selectedFields="selectedFields"
            :year="String(year)"
            :yearList="priorPracticesYears"
            :dataCompletionMaster="dataCompletionMaster"
            @update-data-complete="refreshOnSave"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex"
import _ from "lodash"
import { CROP_DISPLAY_NAME } from "@/constants"
import { Defaults, Fields, Filter, Map, Organization } from "@/store/modules"

// import WizardControlBar from "@/components/wizard/WizardControlBar"
// import InitialWizard from "@/components/wizard/InitialWizard"
import NewCoverCropWizard from "@/components/wizard/NewCoverCropWizard"
import NewTillageWizard from "@/components/wizard/NewTillageWizard"
import NewIrrigationWizard from "@/components/wizard/NewIrrigationWizard"
import NitrogenReductionWizard from "@/components/wizard/NitrogenReductionWizard"
import NewFertilizerWizard from "@/components/wizard/NewFertilizerWizard"
import ProtectionWizard from "@/components/wizard/ProtectionWizard"
import NewLimingWizard from "@/components/wizard/NewLimingWizard"
import NewPlantingWizard from "@/components/wizard/NewPlantingWizard"
import ConservationProgramWizard from "@/components/wizard/ConservationProgramWizard"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import WizardToolBar from "@/components/wizard/WizardToolBar"
import Permissions from "@/components/permissions/Permissions"

export default {
  components: {
    NewCoverCropWizard,
    NewTillageWizard,
    NewIrrigationWizard,
    NitrogenReductionWizard,
    NewFertilizerWizard,
    ProtectionWizard,
    NewLimingWizard,
    DashboardTitle,
    WizardToolBar,
    NewPlantingWizard,
    ConservationProgramWizard,
    Permissions,
  },

  data() {
    return {
      e1: 1,
      hovered: false,
      // loading/api feedback info
      loadingText: "Getting Historical Field Information",
      errorText: "",
      // need smarter controller for which wizard
      currentWizard: null,
      showIntro: true,
      showLoading: false,
      loadComplete: false,
      loadError: false,
      showDataCompleteLoading: true,
      showCoverCropWizard: false,
      showTillageWizard: false,
      showIrrigationWizard: false,
      showPlantingWizard: false,
      showLimingWizard: false,
      showReductionWizard: false,
      showCoverCropUpdated: false,
      showFertilizerWizard: false,
      showProtectionWizard: false,
      // previous wizard
      previousWizard: null,
      lastWizard: null,
      // interwizard nagivation
      // year selector
      priorYear: "All Years",
      // credit price per ton
      creditPrice: 20,
      showFetchButton: false,
      // summary display view
      cropDisplayName: CROP_DISPLAY_NAME,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
    }
  },

  computed: {
    ...mapState({
      years: state => state.Organization.organization.years,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      featureFlags: state => state.Fields.featureFlags,
    }),

    ...mapGetters({
      selectedFields: Filter.Getters.getSelectedFields,
      year: Organization.Getters.getYear,
      dataCompletionMaster: Defaults.Getters.getWizardDataCompletionSummary
    }),
    dashboardTitle() {
      if (this.currentWizard && this.year) {
        return this.currentWizard + " for " + this.year
      }
      return "Update and Confirm Management Practices"
    },
    chartData() {
      if (!this.dataCompletionMaster) return null
      // new method
      let dataCompList = []

      for (const [key, value] of Object.entries(this.dataCompletionMaster)) {
        let sumCompletion
        // check for if a year selected, else display aggregate data completion for all years
        // if (this.priorYearLogic) {
        sumCompletion = value.reduce((a, o) => {
          // check for new key with year by year data complete
          if ("by_year" in o.data_comp) {
            let year_total = o.data_comp.by_year.find(
              yearObj => yearObj.year == this.year
            )
            if (year_total) {
              return a + year_total.avg_for_year
            } else {
              // fills in a 0 for fields missing year data for category
              return a + 0
            }
          } else {
            // old calculation which grabs total data by year
            return a + o.data_comp.total
          }
        }, 0)
        // } else {
        //   sumCompletion = value.reduce((a, o) => {
        //     return a + o.data_comp.total
        //   }, 0)
        // }
        let lenCompletion = value.length
        dataCompList.push({
          label: key,
          completion: Math.ceil(sumCompletion / lenCompletion),
        })
      }
      return dataCompList
    },

    chartSeries() {
      if (!this.dataCompletionMaster) return null
      return this.chartData.map(d => d.completion)
    },

    chartOptions() {
      if (!this.dataCompletionMaster) return null
      const labels = this.chartData.map(d => d.label)
      const totalLabel = "Carbon Ready"
      return {
        chart: { type: "radialBar" },
        colors: [
          "#17986A",
          "#046E94",
          "#2E85D0",
          "#0A3389",
          "#432AA7",
          "#558B2F",
          "#633162",
        ],
        plotOptions: {
          radialBar: {
            // NOTE - Rotates polar clock angle
            // startAngle: -180,
            // endAngle: 180,
            dataLabels: {
              name: { fontSize: "22px" },
              value: { fontSize: "16px" },
              total: {
                show: true,
                label: totalLabel,
                formatter: () => "",
              },
            },
            hollow: {
              margin: 0,
              size: "40%",
              background: "transparent",
            },
          },
        },
        stroke: { lineCap: "round" },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "diagonal1",
            gradientToColors: [
              "#47B88A",
              "#348EA4",
              "#4EA5BF",
              "#4A73BF",
              "#51207E",
              "#89db64",
              "#883a66",
            ],
            stops: [0, 100],
          },
        },
        labels,
        legend: {
          show: true,
          floating: true,
          position: "left",
          offsetY: 5,
        },
      }
    },

    priorYearLogic() {
      if (typeof this.priorYear !== "number") return null
      return this.priorYear
    },

    priorPracticesYears() {
      if (!this.years || typeof this.years === "undefined") {
        return ["No Data Found"]
      }

      if (_.isEmpty(this.years)) {
        // TO DO: Reenable when logic for creating defaults for a year with no planting/harvest data (no year info) is fixed
        let currentDate = new Date()
        let currentYear = currentDate.getFullYear()
        let currentYears = [
          currentYear - 1,
          currentYear - 2,
          currentYear - 3,
          currentYear - 4,
        ]
        currentYears.unshift("All Years")

        return currentYears
      }

      let yearsCopy = _.cloneDeep(this.years.map(y => Number(y)))
      yearsCopy.unshift("All Years")
      return yearsCopy
    },

    totalCreditAmount() {
      if (!this.selectedFields) return null
      let rows = []
      this.selectedFields.forEach(field => {
        //this should only list fields that are not in a currently active enrollment?
        field.daycent.map(d => {
          const equiv =
            (parseFloat(d.sysc) * 3.666 * 4046.86 +
              parseFloat(d.ch4) * -1 * 24 * 4046.86 + //this is wrong?? should be 24...
              parseFloat(d.n20) * -1 * 298 * 4046.86) /
            1000000

          //clean this up
          let any_selected = false

          let no_till = this.getPracticeSetting(d, "no-till")
          let cover_crop = this.getPracticeSetting(d, "cover-crop")
          let inhibitor = this.getPracticeSetting(d, "nitrogen-inhibitor")
          let awd = this.getPracticeSetting(d, "awd")

          if (inhibitor.is_selected) any_selected = true
          if (cover_crop.is_selected) any_selected = true
          if (no_till.is_selected) any_selected = true
          if (awd.is_selected) any_selected = true

          // if (any_selected) {
          //   const index = this.rowsToEdit.indexOf(d.id)
          //   if (index == -1) {
          //     this.rowsToEdit.push(d.id)
          //     this.selectedFieldIds.push(field.id)
          //   }
          // }

          let creditAmt = 0
          let co2EquivalentSelected = 0
          if (any_selected) {
            creditAmt =
              equiv.toFixed(2) * this.creditPrice * field.acreage.toFixed(0)
            co2EquivalentSelected = creditAmt / this.creditPrice
          }

          return Object.assign(d, {
            fieldId: field.id,
            name: field.name,
            acreage: field.acreage,
            boundary_image: field.boundary_image,
            co2Equivalent: equiv,
            status: d.status,
            no_till: no_till,
            cover_crop: cover_crop,
            inhibitor: inhibitor,
            awd: awd,
            is_complete: false,
            //no-till: d.
            //updated: field.daycent.updated_at,
            //settings: field.daycent.scenario_settings,
            co2EquivalentSelected: co2EquivalentSelected,
            creditAmount: creditAmt, //add field_id,last simulation options here, last simulation time, processing status
          })
        })
        rows.push(...field.daycent)
      })

      const totalCredit = rows
        .map(r => parseFloat(r.creditAmount))
        .reduce((prev, curr) => {
          return curr < 0 ? prev : prev + curr
        }, 0)
      return totalCredit
    },
  },

  methods: {
    ...mapActions({
      fetchCarbonSettingsByField: Defaults.Actions.fetchCarbonSettingsByField,
      fetchField: Fields.Actions.fetchField,
      updateWizardDataCompletionSummary: Defaults.Actions.updateWizardDataCompletionSummary,
    }),

    ...mapMutations({
      autoZoom: Map.Mutations.autoZoom,
      updateMap: Map.Mutations.updateMap,
      updatePlanting: Defaults.Mutations.updatePlanting,
      resetRecentWizardData: Defaults.Mutations.resetRecentWizardData,
    }),

    ...mapGetters({
      getWizardDataCompletionSummary: Defaults.Getters.getWizardDataCompletionSummary,
    }),

    canClickSubwizard(label) {
      if (this.chartData) {
        if (isNaN(this.chartData.find(x => x.label == label)["completion"]))
          return false
      }
      return true
    },

    continueWizardLoadingRequest() {
      if (this.lastWizard) {
        this.handleGoToWizard(this.lastWizard)
      }
      this.showFetchButton = false
    },

    async handleDataCompleteAPI() {
      //let yearList = [2018, 2019, 2020, 2021, 2022, 2023]

      this.showDataCompleteLoading = true
      if (this.selectedFields.length == 0) {
        return
      }
      await this.updateWizardDataCompletionSummary({
        fieldIds: this.selectedFields.map(x => x.id),
      })

      this.showDataCompleteLoading = false
    },

    async refreshOnSave() {
      await this.refreshDataCompletionSummary()
      for (const field of this.selectedFields) {
        // this refreshes completion (and potentially other stuff?) for the field in the rest of the app
        await this.fetchField({ fieldId: field.id })
      }
    },

    async refreshDataCompletionSummary() {
      // grab all years in consideration from sidenav for data completion values for wizard field selection table
      let yearList = _.cloneDeep(this.years.map(y => Number(y)))

      this.showDataCompleteLoading = true

      // const delay = ms => new Promise(res => setTimeout(res, ms))
      // await delay(2000)

      await this.updateWizardDataCompletionSummary({
        fieldIds: this.selectedFields.map(x => x.id),
        yearList: yearList,
      })
      this.showDataCompleteLoading = false
    },

    async handleLoadComplete(wizard) {
      this.previousWizard = wizard
      await this.refreshDataCompletionSummary()
      this.showLoading = false
      this.loadComplete = true
      this.loadingText = "Getting Historical Field Information"
    },

    async handleLoadCompleteNew(wizard, payload, fieldList, wantedKeys) {
      this.previousWizard = wizard
      this.summaryFields = fieldList
      this.summaryData = payload
      this.summaryKeys = wantedKeys
      await this.refreshDataCompletionSummary()
      this.showLoading = false
      this.loadComplete = true
      this.loadingText = "Getting Historical Field Information"
    },

    handleReset() {
      this.currentWizard = null
      this.showIntro = true
      this.showLoading = false
      this.loadComplete = false
      this.showCoverCropWizard = false
      this.showCoverCropUpdated = false
      this.showTillageWizard = false
      this.showIrrigationWizard = false
      this.showPlantingWizard = false
      this.showLimingWizard = false
      this.showReductionWizard = false
      this.showFertilizerWizard = false
      this.showProtectionWizard = false
      this.resetRecentWizardData()
    },

    async getCarbonSettings() {
      const fieldIds = this.selectedFields.map(f => f.id)
      await this.fetchCarbonSettingsByField({ fieldIds: fieldIds })
    },

    async handleGoToWizard(wizard, returnToPrevious = false) {
      this.lastWizard = wizard
      this.turnOffEverything()
      this.currentWizard = "Loading Data"

      if (!returnToPrevious) {
        this.showLoading = true
        let selectedYear = this.priorYear === "All Years" ? null : this.priorYear
        const fieldIds = this.selectedFields.map(f => f.id)

        let fetchResponse = await this.fetchCarbonSettingsByField({
          fieldIds: fieldIds,
          year: selectedYear,
        })

        this.showLoading = false

        if (fetchResponse) {
          this.loadError = true
          this.errorText = fetchResponse
          if (String(fetchResponse).includes("timeout")) {
            this.showFetchButton = true
          }
          return
        }
      }

      if (wizard === "planting") {
        this.currentWizard = "Planting"
        this.showPlantingWizard = true
      } else if (wizard === "covercrop") {
        this.currentWizard = "Cover Cropping"
        this.showCoverCropWizard = true
      } else if (wizard === "tillage") {
        this.currentWizard = "Tillage"
        this.showTillageWizard = true
      } else if (wizard === "irrigation") {
        this.currentWizard = "Irrigation and Drainage"
        this.showIrrigationWizard = true
      } else if (wizard === "fertilizer") {
        this.currentWizard = "Fertilizer Applications"
        this.showFertilizerWizard = true
      } else if (wizard === "protection") {
        this.currentWizard = "Crop Protection"
        this.showProtectionWizard = true
      } else if (wizard === "liming") {
        this.currentWizard = "Liming"
        this.showLimingWizard = true
      } else if (wizard === "Conservation Programs") {
        this.currentWizard = "Conservation Programs"
      } else if (wizard === "Harvest") {
        this.currentWizard = "Harvest";
        this.$router.push(`/historical-practices/harvest`);
      } else if (wizard === "Soil Sampling") {
        this.currentWizard = "Soil Sampling";
        this.$router.push(`/historical-practices/soil-sampling`);
      }
      if (wizard == "Harvest") {
        if (!returnToPrevious) {
          this.showLoading = true
          let fetchResponse = await this.fetchCarbonSettingsByField({
            fieldIds: fieldIds,
            year: selectedYear,
          })
          this.showLoading = false
        }
        this.currentWizard = "Harvest"
        this.$router.push(`/historical-practices/harvest`)
      }
    },
    turnOffEverything(resetWizard = true) {
      if (resetWizard) {
        this.currentWizard = null
      }
      // clear intro and loading views, reset text
      this.showIntro = false
      this.showLoading = false
      this.loadComplete = false
      this.loadError = false
      this.errorText = ""
      // turn off all wizards
      this.showCoverCropWizard = false
      this.showCoverCropUpdated = false
      this.showTillageWizard = false
      this.showIrrigationWizard = false
      this.showPlantingWizard = false
      this.showLimingWizard = false
      this.showReductionWizard = false
      this.showFertilizerWizard = false
      // reset summary info
      this.summaryData = null
      this.summaryFields = null
      this.summaryKeys = null
    },

    handleFieldSelectClick() {
      this.$router.push("/sustainability")
    },

    handleBeginEnrollClick() {
      this.$router.push("/enroll-fields")
    },

    handleImportClicked() {
      this.$router.push(`/uploads`)
    },

    // adding in for co2e price calculations from sustainability page
    getPracticeSetting(d, practice_name) {
      let practiceSetting = d.scenario.filter(x => {
        return x.regen_practice.name == practice_name
      })
      if (practiceSetting.length == 1) {
        practiceSetting = {
          value: practiceSetting[0].value,
          is_selected: Boolean(practiceSetting[0].is_selected),
          is_locked: Boolean(practiceSetting[0].is_locked),
        }
      } else {
        practiceSetting = { value: "Unavailable", is_selected: false }
      }
      return practiceSetting
    },
  },

  mounted() {
    this.handleDataCompleteAPI()
  },

  watch: {
    priorYear() {
      this.handleDataCompleteAPI()
    },
    selectedFields: {
      handler: async function (newFields, oldFields) {
        const newFieldIds = newFields.map(f => f.id)
        const oldFieldIds = oldFields.map(f => f.id)

        if (!_.isEqual(newFieldIds, oldFieldIds)) {
          this.handleDataCompleteAPI()

          let selectedYear = this.priorYear
          if (selectedYear == "All Years") {
            selectedYear = null
          }

          // syncs the navbar with sub-wizard field selection
          if (this.currentWizard != null) {
            this.turnOffEverything(false)
            const fieldIds = this.selectedFields.map(f => f.id)
            this.showLoading = true

            await this.fetchCarbonSettingsByField({
              fieldIds: fieldIds,
              year: selectedYear,
            }).then(() => {
              this.showLoading = false
              if (this.currentWizard == "Cover Cropping") {
                this.showCoverCropWizard = true
              } else if (this.currentWizard == "Tillage") {
                this.showTillageWizard = true
              } else if (this.currentWizard == "Irrigation and Drainage") {
                this.showIrrigationWizard = true
              } else if (this.currentWizard == "Fertilizer Applications") {
                this.showFertilizerWizard = true
              } else if (this.currentWizard == "Crop Protection") {
                this.showProtectionWizard = true
              } else if (this.currentWizard == "Liming") {
                this.showLimingWizard = true
              } else if (this.currentWizard == "Planting") {
                this.showPlantingWizard = true
              }
            })
          }
        }
      },
    },
  },
}
</script>
<style scoped>
.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-col {
  padding-left: 0px;
}

.map-toggle {
  position: absolute;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 105%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.25s;
}
.slide-enter {
  transform: translate(50%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.testing {
  min-height: 396px;
  /* border: solid; */
}

.welcome-wagon {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
}

.select-container {
  width: 200px;
}

.beta-tag {
  margin-left: 6px;
  margin-right: 6px;
  font-weight: bold;
  font-size: 10px;
  color: white;
  background-color: #28a745;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;
  height: 18px;
  line-height: 18px;
  margin-top: 6px;
}

.loading-data-complete {
  margin-top: 80px;
  min-height: 300px;
  height: 300px;
}
</style>
