<template>
  <tr>
    <td v-if="!isReadOnly">
      <input
        @click="checkboxClicked"
        type="checkbox"
        name="selected"
        ref="fieldSelectedCheckbox"
      />
    </td>
    <td>
      <img :src="field.boundary_image" style="width: 100px" />
    </td>
    <td>
      <div class="default-name" @click="handleCarbonClicked()">
        {{ field.name }}
      </div>

      <v-tooltip
        v-if="field.is_initializing_or_processing"
        color="black"
        bottom
        max-width="200px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" color="orange">mdi-sync</v-icon>
        </template>
        <span>Field is processing: data may be incomplete.</span>
      </v-tooltip>
    </td>
    <td>
      <div v-if="field.inactive">
        <i class="fa fa-lock"></i>
      </div>
    </td>
    <td>{{ field.acreage | prettyInteger }}</td>
    <td>{{ field.farm.name }}</td>
    <td>
      <div
        v-if="showSustainability"
        class="default-name"
        @click="handleCarbonClicked()"
      >
        Worksheet
      </div>
      <div v-else class="default-name" @click="handleCarbonClicked()">
        Worksheet
      </div>
    </td>
    <td v-if="!isReadOnly" class="pre-confidence-cell">
      <div v-if="!completionIncludeDefaults">
        <input
          @click="dataCheckBoxClicked('Planting')"
          type="radio"
          name="selected"
          ref="PlantingFieldSelectedRadio"
        />
      </div>

      <div v-if="!completionIncludeDefaults">
        <input
          @click="dataCheckBoxClicked('Applied')"
          type="radio"
          name="selected"
          ref="AppliedFieldSelectedRadio"
        />
      </div>

      <div v-if="!completionIncludeDefaults">
        <input
          @click="dataCheckBoxClicked('Harvest')"
          type="radio"
          name="selected"
          ref="HarvestFieldSelectedRadio"
        />
      </div>

      <!-- <div>
        <input
                    @click="dataCheckBoxClicked('Soil')"
                    type="radio"
                    name="selected"
                    ref="SoilFieldSelectedRadio"
                  />
      </div>

      <div>
        <input
                    @click="dataCheckBoxClicked('Texture')"
                    type="radio"
                    name="selected"
                    ref="TextureFieldSelectedRadio"
                  />
      </div> -->
    </td>
    <td v-else class="pre-confidence-cell"></td>
    <td>
      <div class="confidence-factors" v-if="!completionIncludeDefaults">
        <div class="confidence-box">
          <div class="data-type">
            <i
              :class="[
                'check',
                'fa',
                'fa-check',
                checkPercentForColor(plantingPercent),
              ]"
            />Planting
          </div>
          <div class="pct">{{ plantingPercent | percent }}</div>
          <div
            v-if="showImport(plantingPercent) && !isReadOnly"
            class="data-import"
          >
            <router-link
              :to="{ name: 'uploads', query: { openUpload: 'planting' } }"
            >
              Import Planting Data
              <i class="fa fa-arrow-circle-right" />
            </router-link>
          </div>
        </div>
        <div class="confidence-box">
          <div class="data-type">
            <i
              :class="[
                'check',
                'fa',
                'fa-check',
                checkPercentForColor(fertPercent),
              ]"
            />Applied
          </div>
          <div class="pct">{{ fertPercent | percent }}</div>
          <div
            v-if="showImport(fertPercent) && !isReadOnly"
            class="data-import"
          >
            <router-link
              :to="{ name: 'uploads', query: { openUpload: 'fert' } }"
            >
              Import Fertilizer Data
              <i class="fa fa-arrow-circle-right" />
            </router-link>
          </div>
        </div>
        <div class="confidence-box">
          <div class="data-type">
            <i
              :class="[
                'check',
                'fa',
                'fa-check',
                checkPercentForColor(harvestPercent),
              ]"
            />Harvest
          </div>
          <div class="pct">{{ harvestPercent | percent }}</div>
          <div
            v-if="showImport(harvestPercent) && !isReadOnly"
            class="data-import"
          >
            <router-link
              :to="{ name: 'uploads', query: { openUpload: 'yield' } }"
            >
              Import Harvest Data
              <i class="fa fa-arrow-circle-right" />
            </router-link>
          </div>
        </div>
        <div class="confidence-box">
          <div class="data-type">
            <i
              :class="[
                'check',
                'fa',
                'fa-check',
                checkPercentForColor(soilPercent),
              ]"
            />Soil
          </div>
          <div class="pct">{{ soilPercent | percent }}</div>
          <div
            v-if="showImport(soilPercent) && !isReadOnly"
            class="data-import"
          >
            <router-link
              :to="{ name: 'uploads', query: { openUpload: 'soil-sample' } }"
            >
              Import Soil Data
              <i class="fa fa-arrow-circle-right" />
            </router-link>
          </div>
        </div>
        <div class="confidence-box">
          <div class="data-type">
            <i
              :class="[
                'check',
                'fa',
                'fa-check',
                checkPercentForColor(texturePercent),
              ]"
            />Texture
          </div>
          <div class="pct">{{ texturePercent | percent }}</div>
          <div
            v-if="showImport(texturePercent) && !isReadOnly"
            class="data-import"
          >
            <router-link :to="{ name: 'uploads', query: { openUpload: 'ec' } }">
              Import Soil Texture Data
              <i class="fa fa-arrow-circle-right" />
            </router-link>
          </div>
        </div>
      </div>
      <div class="confidence-factors" v-else>
        <div class="d-flex">
          <div>
            <div class="confidence-box">
              <div class="defaults-type">
                <i
                  :class="[
                    'check',
                    'fa',
                    'fa-check',
                    checkCompletionForColor(plantingDefaultsPercent),
                  ]"
                />Planting
              </div>
              <div class="pct">{{ plantingDefaultsPercent | floatTenth }}%</div>
            </div>
            <div class="confidence-box">
              <div class="defaults-type">
                <i
                  :class="[
                    'check',
                    'fa',
                    'fa-check',
                    checkCompletionForColor(fertilizerDefaultsPercent),
                  ]"
                />Applied
              </div>
              <div class="pct">
                {{ fertilizerDefaultsPercent | floatTenth }}%
              </div>
            </div>
            <div class="confidence-box">
              <div class="defaults-type">
                <i
                  :class="[
                    'check',
                    'fa',
                    'fa-check',
                    checkCompletionForColor(harvestDefaultsPercent),
                  ]"
                />Harvest
              </div>
              <div class="pct">{{ harvestDefaultsPercent | floatTenth }}%</div>
            </div>
          </div>
          <div class="ml-auto">
            <div class="confidence-box">
              <div class="defaults-type">
                <i
                  :class="[
                    'check',
                    'fa',
                    'fa-check',
                    checkCompletionForColor(coverCroppingPercent),
                  ]"
                />Cover Cropping
              </div>
              <div class="pct">{{ coverCroppingPercent | floatTenth }}%</div>
            </div>
            <div class="confidence-box">
              <div class="defaults-type">
                <i
                  :class="[
                    'check',
                    'fa',
                    'fa-check',
                    checkCompletionForColor(cropProtectionPercent),
                  ]"
                />Crop Protection
              </div>
              <div class="pct">{{ cropProtectionPercent | floatTenth }}%</div>
            </div>
            <div class="confidence-box">
              <div class="defaults-type">
                <i
                  :class="[
                    'check',
                    'fa',
                    'fa-check',
                    checkCompletionForColor(irrigationPercent),
                  ]"
                />Irrigation
              </div>
              <div class="pct">{{ irrigationPercent | floatTenth }}%</div>
            </div>
            <div class="confidence-box">
              <div class="defaults-type">
                <i
                  :class="[
                    'check',
                    'fa',
                    'fa-check',
                    checkCompletionForColor(limingPercent),
                  ]"
                />Liming
              </div>
              <div class="pct">{{ limingPercent | floatTenth }}%</div>
            </div>
            <div class="confidence-box">
              <div class="defaults-type">
                <i
                  :class="[
                    'check',
                    'fa',
                    'fa-check',
                    checkCompletionForColor(tillagePercent),
                  ]"
                />Tillage
              </div>
              <div class="pct">{{ tillagePercent | floatTenth }}%</div>
            </div>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "FieldListRow",

  props: [
    "field",
    "organization",
    "completionIncludeDefaults",
    "showSustainability",
  ],

  data() {
    return {
      geoPkgsByDataType: [],
    }
  },

  computed: {
    ...mapState({
      year: state => state.Organization.year,
      isReadOnly: state => state.User.user.permission === "Read-Only",
    }),

    defaults() {
      return this.field.defaults || this.organization.defaults || {}
    },

    plantingPercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.completionIncludeDefaults) return 0

      data_completion = data_completion || {}
      data_completion = data_completion[this.year] || {}
      defaults_data_completion = defaults_data_completion[this.year] || {}

      const { planting_acres = 0 } = data_completion
      const { planting_acres: defaultsPlantingAcres = 0 } =
        defaults_data_completion

      const acres = this.completionIncludeDefaults
        ? Math.max(planting_acres, defaultsPlantingAcres)
        : planting_acres

      return acres / acreage
    },

    fertPercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.completionIncludeDefaults) return 0

      data_completion = data_completion || {}
      data_completion = data_completion[this.year] || {}
      defaults_data_completion = defaults_data_completion[this.year] || {}

      const { fertilizer_acres = 0 } = data_completion
      const { fertilizer_acres: defaultsFertAcres = 0 } =
        defaults_data_completion

      const acres = this.completionIncludeDefaults
        ? Math.max(fertilizer_acres, defaultsFertAcres)
        : fertilizer_acres

      return acres / acreage
    },

    harvestPercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.completionIncludeDefaults) return 0

      data_completion = data_completion || {}
      data_completion = data_completion[this.year] || {}
      defaults_data_completion = defaults_data_completion[this.year] || {}

      const { harvest_acres = 0 } = data_completion
      const { harvest_acres: defaultsHarvestAcres = 0 } =
        defaults_data_completion

      const acres = this.completionIncludeDefaults
        ? Math.max(harvest_acres, defaultsHarvestAcres)
        : harvest_acres

      return acres / acreage
    },

    soilPercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.completionIncludeDefaults) return 0

      data_completion = data_completion || {}
      for (const year of Object.keys(data_completion)) {
        data_completion = data_completion[year]
        break
      }
      defaults_data_completion = defaults_data_completion || {}

      const { soil_samples_acres: soilSamplesAcres = 0 } = data_completion
      const { soil_samples_acres: defaultsSoilSamplesAcres = 0 } =
        defaults_data_completion

      const soilSamplesPercent = soilSamplesAcres / acreage
      const defaultsSoilSamplesPercent = defaultsSoilSamplesAcres / acreage

      const percent = this.completionIncludeDefaults
        ? Math.max(soilSamplesPercent, defaultsSoilSamplesPercent)
        : soilSamplesPercent

      return percent
    },

    texturePercent() {
      let { acreage, data_completion, defaults_data_completion } = this.field

      if (!data_completion && !this.completionIncludeDefaults) return 0

      data_completion = data_completion || {}
      for (const year of Object.keys(data_completion)) {
        data_completion = data_completion[year]
        break
      }
      defaults_data_completion = defaults_data_completion || {}

      const { soil_texture_acres: soilTextureAcres = 0 } = data_completion
      const { soil_texture_acres: defaultsSoilTextureAcres = 0 } =
        defaults_data_completion

      const soilTexturePercent = soilTextureAcres / acreage
      const defaultsSoilTexturePercent = defaultsSoilTextureAcres / acreage

      const percent = this.completionIncludeDefaults
        ? Math.max(soilTexturePercent, defaultsSoilTexturePercent)
        : soilTexturePercent

      return percent
    },

    // Sustainability (Defaults) Completion
    plantingDefaultsPercent() {
      let { defaults_data_completion } = this.field
      let plantingCompletion = defaults_data_completion.planting.by_year.find(
        planting => planting.year == parseInt(this.year)
      )
      if (plantingCompletion) {
        return plantingCompletion.avg_for_year
      } else {
        return 0
      }
    },

    harvestDefaultsPercent() {
      let { defaults_data_completion } = this.field
      let harvestCompletion = defaults_data_completion.harvest.by_year.find(
        harv => harv.year == parseInt(this.year)
      )
      if (harvestCompletion) {
        return harvestCompletion.avg_for_year
      } else {
        return 0
      }
    },

    coverCroppingPercent() {
      let { defaults_data_completion } = this.field
      let ccCompletion = defaults_data_completion.cover_cropping.by_year.find(
        cc => cc.year == parseInt(this.year)
      )
      if (ccCompletion) {
        return ccCompletion.avg_for_year
      } else {
        return 0
      }
    },

    cropProtectionPercent() {
      let { defaults_data_completion } = this.field
      let protectionCompletion =
        defaults_data_completion.crop_protection.by_year.find(
          till => till.year == parseInt(this.year)
        )
      if (protectionCompletion) {
        return protectionCompletion.avg_for_year
      } else {
        return 0
      }
    },

    fertilizerDefaultsPercent() {
      let { defaults_data_completion } = this.field
      let ccCompletion = defaults_data_completion.fert.by_year.find(
        cc => cc.year == parseInt(this.year)
      )
      if (ccCompletion) {
        return ccCompletion.avg_for_year
      } else {
        return 0
      }
    },

    irrigationPercent() {
      let { defaults_data_completion } = this.field
      let irrigCompletion = defaults_data_completion.irrig.by_year.find(
        irr => irr.year == parseInt(this.year)
      )
      if (irrigCompletion) {
        return irrigCompletion.avg_for_year
      } else {
        return 0
      }
    },

    limingPercent() {
      let { defaults_data_completion } = this.field
      let limingCompletion = defaults_data_completion.liming.by_year.find(
        lime => lime.year == parseInt(this.year)
      )
      if (limingCompletion) {
        return limingCompletion.avg_for_year
      } else {
        return 0
      }
    },

    tillagePercent() {
      let { defaults_data_completion } = this.field
      let tillageCompletion = defaults_data_completion.tillage.by_year.find(
        till => till.year == parseInt(this.year)
      )
      if (tillageCompletion) {
        return tillageCompletion.avg_for_year
      } else {
        return 0
      }
    },
  },

  methods: {
    sendCheckEvents(checkbox) {
      if (checkbox.checked) this.$emit("checked", this.field.id)
      else this.$emit("unchecked", this.field.id)
    },

    checkboxClicked() {
      const checkbox = this.$refs.fieldSelectedCheckbox
      this.sendCheckEvents(checkbox)
    },

    setCheckbox(checked) {
      const checkbox = this.$refs.fieldSelectedCheckbox
      checkbox.checked = checked
      this.sendCheckEvents(checkbox)
    },

    async sendDataCheckEvents(radio, dataType) {
      if (radio.checked) {
        this.$emit("data-checked", this.field.id, dataType)
        setTimeout(() => {
          radio.checked = false
        }, 2000)
      }
    },

    dataCheckBoxClicked(dataType) {
      if (dataType === "Planting") {
        const checkbox = this.$refs.PlantingFieldSelectedRadio
        this.sendDataCheckEvents(checkbox, dataType)
      }
      if (dataType === "Applied") {
        const checkbox = this.$refs.AppliedFieldSelectedRadio
        this.sendDataCheckEvents(checkbox, dataType)
      }
      if (dataType === "Harvest") {
        const checkbox = this.$refs.HarvestFieldSelectedRadio
        this.sendDataCheckEvents(checkbox, dataType)
      }
      if (dataType === "Soil") {
        const checkbox = this.$refs.SoilFieldSelectedRadio
        this.sendDataCheckEvents(checkbox, dataType)
      }
      if (dataType === "Texture") {
        const checkbox = this.$refs.TextureFieldSelectedRadio
        this.sendDataCheckEvents(checkbox, dataType)
      }
    },

    setArrayFieldDataCheckbox(checked, selectedDataTypeList) {
      if (selectedDataTypeList.includes("Planting")) {
        const plantingFieldCheckbox = this.$refs.PlantingFieldSelectedCheckbox
        plantingFieldCheckbox.checked = checked
        plantingFieldCheckbox.values = this.field.id
        this.sendDataCheckEvents(plantingFieldCheckbox, "Planting")
      }

      if (selectedDataTypeList.includes("Applied")) {
        const appliedFieldCheckbox = this.$refs.AppliedFieldSelectedCheckbox
        appliedFieldCheckbox.checked = checked
        appliedFieldCheckbox.values = this.field.id
        this.sendDataCheckEvents(appliedFieldCheckbox, "Applied")
      }

      if (selectedDataTypeList.includes("Harvest")) {
        const harvestFieldCheckbox = this.$refs.HarvestFieldSelectedCheckbox
        harvestFieldCheckbox.checked = checked
        harvestFieldCheckbox.values = this.field.id
        this.sendDataCheckEvents(harvestFieldCheckbox, "Harvest")
      }

      if (selectedDataTypeList.includes("Soil")) {
        const soilFieldCheckbox = this.$refs.SoilFieldSelectedCheckbox
        soilFieldCheckbox.checked = checked
        soilFieldCheckbox.values = this.field.id
        this.sendDataCheckEvents(soilFieldCheckbox, "Soil")
      }

      if (selectedDataTypeList.includes("Texture")) {
        const textureFieldCheckbox = this.$refs.TextureFieldSelectedCheckbox
        textureFieldCheckbox.checked = checked
        textureFieldCheckbox.values = this.field.id
        this.sendDataCheckEvents(textureFieldCheckbox, "Texture")
      }
    },

    checkPercentForColor(pct) {
      if (typeof pct === "undefined" || pct === 0)
        return "fa-times confident-check-red"
      else if (pct > 0.75) return "confident-check-green"
      else if (pct <= 0.75 && pct >= 0.25) return "confident-check-yellow"
      else if (pct < 0.25) return "confident-check-red"
    },

    checkCompletionForColor(pct) {
      if (typeof pct === "undefined" || pct === 0)
        return "fa-times confident-check-red"
      else if (pct > 75) return "confident-check-green"
      else if (pct <= 75 && pct >= 25) return "confident-check-yellow"
      else if (pct < 25) return "confident-check-red"
    },

    handleCarbonClicked() {
      this.$router.push(`/data-spreadsheet/${this.field.id}`)
    },

    handleSettingsClicked() {
      this.$router.push(`/field/${this.field.id}`)
    },

    showImport(pct) {
      if (pct > 0.75) return false
      return true
    },
  },
}
</script>

<style scoped>
.data-type {
  width: 85px;
}

.defaults-type {
  width: 130px;
}

.pct {
  font-weight: 500;
}

.default-name {
  color: #1979f1;
  font-weight: bold;
}

.default-name:hover {
  color: #333;
  cursor: pointer;
}

.pre-confidence-cell {
  width: 60px;
}

.confidence-box {
  display: flex;
  align-items: center;
}

.check {
  margin-right: 5px;
}

.confident-check-green {
  color: green;
}

.confident-check-yellow {
  color: orange;
}

.confident-check-red {
  color: red;
}

.data-import {
  font-weight: 500;
  font-size: 12px;
  flex: 1;
  text-align: right;
}
</style>
