<template>
  <div>
    <DashboardTitle
      title="SuperUser Admin"
      imgSrc="/assets/images/side-nav/admin.png"
    />

    <div v-if="!user.is_superuser" class="row">
      <div class="col-12">
        <v-alert type="error" dismissible>
          You do not have permission to view this page.
        </v-alert>
      </div>
    </div>
    <div v-else class="col-12 scroll-col">
      <v-card>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="mb-4">
            <v-expansion-panel-header
              class="primary text-white font-weight-bold"
              >DJANGO ADMIN INTERFACE</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-btn class="mt-3" @click="loginToDjangoAdmin">
                Click to login
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="mb-4">
            <v-expansion-panel-header
              class="primary text-white font-weight-bold"
              >MOVE CLIENT</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <div class="row mt-3 mb-3">
                <v-btn
                  class="float-right"
                  :loading="loading"
                  @click="clickCreateBtn"
                >
                  <v-icon>mdi-plus</v-icon>
                  Create New Task
                </v-btn>
              </div>
              <div class="row">
                <v-simple-table
                  class="selector-table"
                  fixed-header
                  height="380px"
                  overflow="auto"
                >
                  <thead>
                    <tr>
                      <th width="300">Created At</th>
                      <th width="300">Client</th>
                      <th width="400">Source Organization</th>
                      <th width="400">Target Organization</th>
                      <th width="300">Requested By</th>
                      <th width="300">Release Form Required</th>
                      <th width="300">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <MoveClientListRow
                      v-for="job in moveClientJobs"
                      :key="job.client_id"
                      :job="job"
                    />
                  </tbody>
                </v-simple-table>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="mb-4">
            <v-expansion-panel-header
              class="primary text-white font-weight-bold"
            >
              RENAME EXISTING CLIENT
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="items.length > 0">
                <v-text-field
                  v-model="searchJob"
                  label="Search"
                  class="mx-4"
                  compact
                ></v-text-field>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="searchJob"
                ></v-data-table>
              </div>
              <v-card>
                <v-container fluid>
                  <v-row>
                    <v-col cols="3">
                      <v-row class="pa-6">
                        <v-autocomplete
                          v-model="selectedRenameOrg"
                          label="Existing Org Node"
                          :items="renameOrgOptions"
                          class="mx-4"
                          compact
                        ></v-autocomplete>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <v-row class="pa-6">
                        <v-autocomplete
                          v-model="selectedRenameClient"
                          label="Existing Client Name"
                          :items="renameClientOptions"
                          class="mx-4"
                          compact
                        ></v-autocomplete>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row class="pa-6">
                        <v-text-field
                          v-model="newClient"
                          label="New Client Name"
                          class="mx-4"
                          compact
                        ></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col cols="2">
                      <v-row class="pa-6">
                        <v-btn @click="renameClient">Rename Client</v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>

    <CreateNewMoveClientTaskModal
      v-if="showCreateNewMoveClientTaskModal"
      :clientOrgOptions="clientOrgOptions"
      :targetCorpOptions="targetCorpOptions"
      @close-modal="clickCloseBtnInCreateModal"
      @save-changes="confirmCreateNewMoveClientTask"
    />

    <ConfirmModal
      v-if="confirmCreateJobModalOpen"
      :titleText="confirmMoveClientTitle"
      :confirmText="confirmMoveClientBody"
      @confirm="handleCreateNewMoveClientTask"
      @close-modal="confirmCreateJobModalOpen = false"
    />

    <AlertErrorModal
      v-if="alertErrorModalOpen"
      :titleText="modalTitleText"
      :errorMessage="errorMessageForModal"
      @close-modal="alertErrorModalOpen = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import { User } from "@/store/modules"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import MoveClientListRow from "@/components/superUserAdmin/MoveClientListRow"
import CreateNewMoveClientTaskModal from "@/components/modals/superUserAdmin/CreateNewMoveClientTaskModal"
import FieldsAPI from "@/api/FieldsAPI"
import ConfirmModal from "@/components/modals/ConfirmModal"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import { Filter } from "@/store/modules"

import {
  setIntervalAsync,
  clearIntervalAsync,
} from "set-interval-async/dynamic"

export default {
  name: "SuperUserAdminView",
  components: {
    DashboardTitle,
    MoveClientListRow,
    CreateNewMoveClientTaskModal,
    ConfirmModal,
    AlertErrorModal,
  },

  data() {
    return {
      panel: [0],
      showCreateNewMoveClientTaskModal: false,
      fetchIntervalID: null,
      loading: false,
      moveClientJobs: [],
      pendingClientJobs: [],
      targetCorpOptions: [],
      confirmCreateJobModalOpen: false,
      createJobPayload: {},
      alertErrorModalOpen: false,
      errorMessageForModal: null,
      modalTitleText: null,
      confirmMoveClientTitle: "Confirm Create New Move Client Job",
      confirmMoveClientBody: "Are you sure you want to create this job?",
      newClient: null,
      selectedRenameClient: null,
      selectedRenameOrg: null,
      searchJob: "",
      items: [],
      headers: [
        { text: "Date", value: "created_at" },
        { text: "Organization", value: "org_name" },
        { text: "Existing Client", value: "existing_client_name" },

        { text: "New Client", value: "new_client_name" },
        { text: "Status", value: "stage" },
      ],
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
    }),
    ...mapGetters({
      clients: Filter.Getters.getClients,
      orgNodes: Filter.Getters.getOrgNodes,
    }),

    renameOrgOptions() {
      let options = []
      this.orgNodes.forEach(org => {
        options.push({ text: org.name + " - " + org.id, value: org.id })
      })
      return options
    },
    renameClientOptions() {
      let options = []
      this.clients.forEach(client => {
        options.push({
          text: client.name + " - " + client.id,
          value: client.id,
        })
      })
      return options
    },
  },

  methods: {
    ...mapActions({
      logInToAdmin: User.Actions.logInToAdmin,
    }),

    async clickCreateBtn() {
      this.loading = true
      await FieldsAPI.fetchClientOrgOptions().then(({ data }) => {
        // Filter out the clients that are already in pending state
        this.clientOrgOptions = data.client_org_options.map(org => ({
          ...org,
          clients: org.clients.filter(
            client => !this.pendingClientJobs.includes(client.id)
          ),
        }))
      })

      if (this.targetCorpOptions.length === 0) {
        await this.fetchTargetCorpOptions()
      }

      this.loading = false
      this.showCreateNewMoveClientTaskModal = true
    },

    loginToDjangoAdmin() {
      this.logInToAdmin()
    },

    clickCloseBtnInCreateModal() {
      this.showCreateNewMoveClientTaskModal = false
      this.createJobPayload = {}
    },

    confirmCreateNewMoveClientTask(payload) {
      if (payload.send_release_form) {
        this.confirmMoveClientTitle = "Confirm Request New Move Client Job"
        this.confirmMoveClientBody =
          "Are you sure you want to create this job? The Release Form must be manually signed by both the Grower and the new Channel Partner before moving the selected client onto the new organization."
      } else {
        this.confirmMoveClientTitle = "Confirm Create New Move Client Job"
        this.confirmMoveClientBody = "Are you sure you want to create this job?"
      }
      this.confirmCreateJobModalOpen = true
      this.createJobPayload = payload
    },

    async handleCreateNewMoveClientTask() {
      this.confirmCreateJobModalOpen = false
      await FieldsAPI.createMoveClientTask(this.createJobPayload)
        .then(resp => {
          this.moveClientJobs = resp.data
          this.showCreateNewMoveClientTaskModal = false
        })
        .catch(error => {
          this.modalTitleText = "Failed To Create A New Move Client Job"
          this.errorMessageForModal = [error.response.data.message]
          this.alertErrorModalOpen = true
        })
    },

    async fetchMoveClientJobs() {
      await FieldsAPI.getMoveClientTasks().then(resp => {
        this.moveClientJobs = resp.data
        this.moveClientJobs.forEach(job => {
          // If the job is pending, add the client to the pending list
          if (job.status === "pending" || job.status === "requested") {
            if (!this.pendingClientJobs.includes(job.client.id)) {
              this.pendingClientJobs.push(job.client.id)
            }
          }
        })
      })
    },

    async renameClient() {
      let payload = {
        org_node_id: this.selectedRenameOrg,
        existing_client_id: this.selectedRenameClient,
        new_client_name: this.newClient,
      }
      await FieldsAPI.postRenameClientJob(payload).then(resp => {
        //this.items = []
        resp.data.forEach(job => {
          this.items.push({
            created_at: job.created_at,
            existing_client_name: job.existing_client_name,
            org_name: job.org_node.name,
            new_client_name: job.new_client,
            stage: job.stage,
          })
        })
        this.selectedRenameOrg = null
        this.selectedRenameClient = null
        this.newClient = null
      })
    },
    async fetchRenameClientJobs() {
      this.items = []
      await FieldsAPI.getRenameClientJobs().then(resp => {
        resp.data.forEach(job => {
          this.items.push({
            created_at: job.created_at,
            existing_client_name: job.existing_client_name,
            org_name: job.org_node.name,
            new_client_name: job.new_client,
            stage: job.stage,
          })
        })
      })
    },

    async fetchTargetCorpOptions() {
      await FieldsAPI.fetchTargetCorpOptions().then(({ data }) => {
        this.targetCorpOptions = data
      })
    },
  },

  async mounted() {
    await this.fetchMoveClientJobs()
    await this.fetchRenameClientJobs()
    this.fetchIntervalID = setIntervalAsync(this.fetchMoveClientJobs, 10000)
  },

  destroyed() {
    if (this.fetchIntervalID) clearIntervalAsync(this.fetchIntervalID)
  },
}
</script>

<style scoped></style>
