<template>
  <section class="notifications-view">
    <MoveClientTaskSidebar
      :task="moveClientSidebarSpec"
      :visible="moveClientSidebarVisible"
      @sidebarClosed="handleSidebarClose"
    />

    <EvidencingTaskSidebar
      :task="evidencingSidebarSpec"
      :visible="evidencingSidebarVisible"
      @sidebarClosed="handleSidebarClose"
    />

    <h2>Notifications</h2>

    <div class="notifications-table-wrapper">
      <div class="notifications-table-nav">
        <p class="notifications-table-nav-subhead">
          {{ taskTableHeader }}
        </p>

        <div class="notifications-table-nav-buttons">
          <v-btn-toggle
            v-model="taskTableFilterKey"
            tile
            color="#000000"
            class="tasks-toggler"
            group
            mandatory
          >
            <v-btn height="32px" text value="all"
              >All ({{ numIncompletedTaskRows + numCompletedTaskRows }})</v-btn
            >
            <v-btn height="32px" text value="incomplete"
              >Incomplete ({{ numIncompletedTaskRows }})</v-btn
            >
            <v-btn height="32px" text value="complete"
              >Completed ({{ numCompletedTaskRows }})</v-btn
            >
          </v-btn-toggle>
        </div>
      </div>

      <v-simple-table
        class="notifications-table"
        v-if="filteredSortedTaskRows.length > 0"
      >
        <thead>
          <th
            v-for="(action, idx) in tableData['tasks']['headers']"
            :key="action + idx"
            :data-header-content="action"
          >
            <a @click="sortTableRows(action)">
              <span>{{ action }}</span>
              <div
                class="notifications-sorting-icons"
                v-if="!['detail', 'status'].includes(action.toLowerCase())"
              >
                <font-awesome-icon
                  :icon="['fas', 'caret-up']"
                  :class="getSortingState(action, 0)"
                />
                <font-awesome-icon
                  :icon="['fas', 'caret-down']"
                  :class="getSortingState(action, 1)"
                />
              </div>
            </a>
          </th>
        </thead>
        <tbody>
          <tr
            v-for="(dataset, datasetIdx) in paginatedTaskRows"
            :key="dataset['assigned'] + datasetIdx"
            class="notifications-table-row"
            @click="setDetail(dataset)"
          >
            <td
              v-for="(column, columnIdx) in tableData['tasks']['headers']"
              :key="column + dataset['assigned'] + columnIdx"
            >
              <NotificationsCell
                :columnLabel="column"
                :columnValue="dataset[column.toLowerCase().replace(' ', '')]"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="empty-table-placeholder" v-else>
        <p>Nothing to display.</p>
      </div>

      <div
        class="pagination-wrapper"
        v-if="filteredSortedTaskRows.length > rowsPerPage"
      >
        <v-btn
          class="decrement-btn"
          fab
          small
          :disabled="!isTableDecrementEnabled()"
          @click="decrementPage()"
        >
          <v-icon dark>mdi-chevron-left</v-icon>
        </v-btn>

        <span
          v-for="(pageNum, idx) in getPageNumbers"
          :key="`page-${pageNum}-${idx}`"
          :class="`${
            tableData['tasks']['page'] == idx ? 'active' : 'inactive'
          } pagination-page`"
          @click="tableData['tasks']['page'] = idx"
        >
          {{ pageNum }}
        </span>

        <v-btn
          class="increment-btn"
          fab
          small
          :disabled="!isTableIncrementEnabled()"
          @click="incrementPage()"
        >
          <v-icon dark>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script>
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults"
import { Notifications } from "@/store/modules"
import { mapState, mapActions, mapGetters } from "vuex"
import NotificationsCell from "@/components/notifications/NotificationsCell"
import MoveClientTaskSidebar from "@/components/notifications/MoveClientTaskSidebar"
import EvidencingTaskSidebar from "@/components/notifications/EvidencingTaskSidebar"
import {
  DEFAULT_ACTIVE_ACTIONS_COLUMNS,
  NOTIFICATION_TITLES,
  NOT_VIEWED,
  NEEDS_REVIEW,
  CRITICAL,
  DONE,
} from "@/constants/arvaNotifications"

export default {
  name: "NotificationsView",
  components: { NotificationsCell, MoveClientTaskSidebar, EvidencingTaskSidebar },
  data() {
    return {
      taskTableFilterKey: "incomplete",
      notifications: [],
      rowsPerPage: 6,
      evidencingSidebarSpec: null,
      moveClientSidebarSpec: null,
      moveClientSidebarVisible: false,
      evidencingSidebarVisible: false,
      tableData: {
        tasks: {
          sort: { key: null, direction: 0 },
          headers: DEFAULT_ACTIVE_ACTIONS_COLUMNS,
          page: 0,
          stateFilters: [NOT_VIEWED, NEEDS_REVIEW, CRITICAL],
        },
      },
    }
  },
  mounted() {
    this.fetchAllNotifications()
  },
  methods: {
    ...mapActions({
      fetchAllNotifications: "Notifications/fetchAllNotifications",
    }),
    handleSidebarClose(refetchNotifications=false) {
      if (refetchNotifications) {
        this.fetchAllNotifications();
      }

      this.moveClientSidebarVisible = false;
      this.evidencingSidebarVisible = false;
      this.evidencingSidebarSpec = null;
      this.moveClientSidebarSpec = null;
    },
    setDetail(obj) {
      if (obj['type'] == 'moveclient') {
        this.moveClientSidebarVisible = true;
        this.evidencingSidebarVisible = false;
        this.moveClientSidebarSpec = obj;
      }

      if (obj['type'] == 'practiceconfirmation') {
        this.evidencingSidebarVisible = true;
        this.moveClientSidebarVisible = false;
        this.evidencingSidebarSpec = obj;
      }
    },
    decrementPage() {
      if (this.isTableDecrementEnabled())
        this.tableData['tasks']["page"] -= 1
    },
    incrementPage() {
      if (this.isTableIncrementEnabled())
        this.tableData['tasks']["page"] += 1
    },
    isTableDecrementEnabled() {
      return this.tableData['tasks']["page"] > 0
    },
    sortTableRows(action) {
      if (["Detail", "Status"].includes(action)) return
      if (this.tableData['tasks']["sort"]["key"] == null) {
        this.tableData['tasks']["sort"]["key"] = action
        this.tableData['tasks']["sort"]["direction"] = 0
      } else if (this.tableData['tasks']["sort"]["key"] == action) {
        if (this.tableData['tasks']["sort"]["direction"] == 0) {
          this.tableData['tasks']["sort"]["direction"] += 1
        } else if (this.tableData['tasks']["sort"]["direction"] == 1) {
          this.tableData['tasks']["sort"]["key"] = null
          this.tableData['tasks']["sort"]["direction"] = 0
        }
      } else {
        this.tableData['tasks']["sort"]["key"] = action
        this.tableData['tasks']["sort"]["direction"] = 0
      }
    },
    getSortingState(action, direction) {
      return this.tableData['tasks']["sort"]["key"] == action &&
        this.tableData['tasks']["sort"]["direction"] == direction
        ? "activated-sort"
        : ""
    },
    getNotificationDescription(notification) {
      let description = ""

      if (notification["notification_type"] == "practiceconfirmation") {
        const { confirmationTypes } = notification["model_related"].reduce(
          (accum, action) => {
            if (
              !accum["confirmationTypes"].includes(
                action["practice_confirmation"]["confirmation_type"]
              )
            ) {
              accum["confirmationTypes"].push(
                EVIDENCING_TYPE_CHOICES.find(
                  ({ value }) =>
                    value ==
                    action["practice_confirmation"]["confirmation_type"]
                )["name"]
              )
            }
            return accum
          },
          { confirmationTypes: [] }
        )

        if (confirmationTypes.length <= 2) {
          description += `${confirmationTypes.join(", ")}`
        } else {
          description += `${confirmationTypes.slice(0, 2).join(", ")} and ${
            confirmationTypes.length - 2
          } more`
        }
      }

      if (notification["notification_type"] == "companywide") {
        description += `Announcement (${new Date(
          notification["created_at"]
        ).toLocaleDateString()})`
      }

      if (notification["notification_type"] == "moveclient") {
        if (notification["model_related"].length == 0) return
        const action = notification["model_related"][0];

        var client_name = action["client"]["name"]
        var source_org = action["source_org"]["name"]
        var destination_org = action["destination_org"]["name"]
        description = `${source_org}/${client_name} -> ${destination_org}/${client_name}`

        if (action['completed']) {
          description += ` (${action['release_form'].length} uploads)`;
        }
      }

      return description
    },
  },
  computed: {
    ...mapState({
      taskNotifications: state => state.Notifications.taskNotifications,
      user: state => state.User.user,
    }),
    ...mapGetters({
      getAggregateTaskNotifications:
        Notifications.Getters.getAggregateTaskNotifications,
    }),
    isTableIncrementEnabled() {
      return (
        this.tableData['tasks']["page"] + 1
      ) * this.rowsPerPage < this.taskRows.length
    },
    getPageNumbers() {
      return Array.from(
        { length: Math.ceil(this.taskRows.length / this.rowsPerPage) },
        (_, i) => i + 1
      )
    },
    taskTableHeader() {
      if (this.taskTableFilterKey == "all") return "All Tasks"
      if (this.taskTableFilterKey == "incomplete")
        return `${this.numIncompletedTaskRows} Incomplete Task${
          this.numIncompletedTaskRows == 1 ? "" : "s"
        }`
      if (this.taskTableFilterKey == "complete")
        return `${this.numCompletedTaskRows} Complete Task${
          this.numCompletedTaskRows == 1 ? "" : "s"
        }`
    },
    numCompletedTaskRows() {
      return this.taskRows.filter(a => a["status"] == DONE).length
    },
    numIncompletedTaskRows() {
      return this.taskRows.filter(a =>
        [NOT_VIEWED, NEEDS_REVIEW, CRITICAL].includes(a["status"])
      ).length
    },
    taskRows() {
      const notifs = this.getAggregateTaskNotifications.map(n => {
        const notifType = n["notification_type"]
        const { id, first_name, last_name, email } = n["targeted_users"][0]
        const status = n["completed"] ? DONE : NEEDS_REVIEW
        const assigned = {
          name: id == this.user["id"] ? "You" : `${first_name} ${last_name}`,
          contact: email,
        }
        return {
          notificationId: n["id"],
          type: notifType,
          title: NOTIFICATION_TITLES[notifType],
          detail: this.getNotificationDescription(n),
          assigned,
          status,
          data: n,
        }
      })
      return notifs
    },
    filteredSortedTaskRows() {
      // prior to sorting, filter out
      let filteredActionRows = this.taskRows

      if (this.tableData["tasks"]["stateFilters"].length > 0) {
        filteredActionRows = this.taskRows.filter(a =>
          this.tableData["tasks"]["stateFilters"].includes(a["status"])
        )
      }

      if (this.tableData["tasks"]["sort"]["key"] == null)
        return filteredActionRows

      const sortKey = this.tableData["tasks"]["sort"]["key"]
        .toLowerCase()
        .replace(" ", "")
      const direction = this.tableData["tasks"]["sort"]["direction"]
      return filteredActionRows.toSorted((a, b) => {
        let aVal = a[sortKey]
        let bVal = b[sortKey]

        if (
          [
            "assigned",
            "client",
            "sent",
            "due",
            "confirmationnumber",
            "fields",
            "title",
          ].includes(sortKey)
        ) {
          if (sortKey == "assigned") {
            return direction == 0
              ? aVal["name"][0].localeCompare(bVal["name"][0])
              : bVal["name"][0].localeCompare(aVal["name"][0])
          } else if (
            sortKey == "client" ||
            sortKey == "confirmationnumber" ||
            sortKey == "fields" ||
            sortKey == "title"
          ) {
            return direction == 0
              ? aVal[0].localeCompare(bVal[0])
              : bVal[0].localeCompare(aVal[0])
          } else return
        }

        return direction == 0 ? aVal - bVal : bVal - aVal
      })
    },
    paginatedTaskRows() {
      return this.filteredSortedTaskRows.slice(
        this.tableData["tasks"]["page"] * this.rowsPerPage,
        (this.tableData["tasks"]["page"] + 1) * this.rowsPerPage
      )
    }
  },
  watch: {
    taskTableFilterKey(curr) {
      if (curr == "complete") this.tableData["tasks"]["stateFilters"] = [DONE]
      if (curr == "incomplete")
        this.tableData["tasks"]["stateFilters"] = [
          NOT_VIEWED,
          NEEDS_REVIEW,
          CRITICAL,
        ]
      if (curr == "all" || curr == null)
        this.tableData["tasks"]["stateFilters"] = []
    },
  },
}
</script>

<style scoped>
.notifications-view {
  position: relative;
  min-height: 800px;
  padding: 24px;
}

.tasks-toggler > button {
  text-transform: none;
  letter-spacing: normal;
  padding: 6px 16px !important;
  opacity: 1;
  transition: opacity 0.25s;
  border-radius: 4px !important;
  margin-left: 0 !important;
  margin-right: 8px !important;
}

.tasks-toggler > button {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.tasks-toggler > button:deep(.v-ripple__container) {
  display: none;
}

.notifications-table-wrapper {
  border: 1px solid rgba(211, 211, 211, 0.5);
  box-shadow: 0 0 5px 0 lightgrey;
  border-radius: 16px;
  padding: 24px;
}

.notifications-view > h2 {
  width: 100%;
  margin: 0 0 32px 0;
  font-size: 24px;
  line-height: 1.5;
}

.notifications-table-wrapper > p {
  color: #687588;
  line-height: 1.6;
  margin: 8px 0 24px;
  position: relative;
}

.notifications-table,
.empty-table-placeholder {
  height: 440px;
  margin-bottom: 24px;
}

.empty-table-placeholder {
  padding: 24px;
}

.empty-table-placeholder > p {
  font-size: 20px;
  line-height: 1.6;
  color: #000000;
}

.notifications-table-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0 24px;
}

.notifications-table-nav-subhead {
  font-size: 16px;
  line-height: 1.25;
  color: #000000;
  font-weight: bold;
  margin: 0;
}

.notifications-table-nav-buttons {
  display: flex;
  align-items: center;
}

.notifications-table th {
  background-color: #fafafa;
}

.notifications-table th:first-of-type {
  border-radius: 10px 0 0 10px;
}

.notifications-table th:last-of-type {
  border-radius: 0 10px 10px 0;
}

.notifications-table th[data-header-content="% Done"] {
  width: 120px;
}

.notifications-table th[data-header-content="Status"] {
  width: 140px;
}

.notifications-table th[data-header-content="Due"],
.notifications-table th[data-header-content="Sent"] {
  width: 150px;
}

.notifications-table th[data-header-content="Type"],
.notifications-table th[data-header-content="Assigned"],
.notifications-table th[data-header-content="Client"],
.notifications-table th[data-header-content="Title"] {
  width: 200px;
}

.notifications-table th[data-header-content="Confirmation Number"] {
  width: 225px;
}

.notifications-table th > a {
  padding: 18px 16px;
  font-size: 12px;
  line-height: 1.6;
  color: #687588;
  display: flex;
  width: 100%;
}

.notifications-table th > a svg {
  color: #cbd5e0;
}

.notifications-table th > a svg.activated-sort {
  color: #687588;
}

.notifications-sorting-icons {
  margin-left: auto;
  position: relative;
}

.notifications-sorting-icons > svg:first-of-type {
  position: absolute;
  top: 1px;
  right: 0;
}

.notifications-sorting-icons > svg:last-of-type {
  position: absolute;
  bottom: 1px;
  right: 0;
}

.notifications-table tr {
  cursor: pointer;
}

.notifications-table-row:nth-child(6n) {
  border-bottom: none;
}

.notifications-table td {
  padding: 18px 16px;
}

td:deep(.notifications-table-cell-name) {
  margin: 0;
  font-size: 12px;
  line-height: 1.6;
  color: #111827;
}

td:deep(.notifications-table-cell-contact) {
  margin: 0;
  font-size: 10px;
  line-height: 1.6;
  color: #a0aec0;
}

tr.notifications-table-row {
  height: 64px;
  border-bottom: 1px solid #f1f2f4;
}

.pagination-wrapper {
  display: flex;
  align-items: center;
}

.decrement-btn {
  margin-right: 12px;
}

.increment-btn {
  margin-left: 12px;
}

.pagination-page {
  font-size: 20px;
  padding: 6px 12px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s;
}

.pagination-page:hover {
  opacity: 0.5;
}

.pagination-page.active {
  text-decoration: underline;
}

::v-deep .b-sidebar-outer {
  z-index: 9999999999;
}

::v-deep .b-sidebar {
  overflow: hidden;
}

::v-deep header.b-sidebar-header {
  margin: 36px 36px 0;
  padding: 0 0 24px;
  position: relative;
  border-bottom: 1px solid #b8bdcc;
}

::v-deep header.b-sidebar-header > button.close {
  font-size: 32px;
  margin-right: 12px !important;
}

::v-deep header.b-sidebar-header > strong {
  width: 100%;
  text-align: left;
}

::v-deep .b-sidebar-backdrop {
  cursor: pointer;
}
</style>
