<template>
  <ArvaModal
    name="clientProfileModificationModal"
    title="Modify Client Profile"
    :wide="true"
    :width="1000"
    @close-modal="handleCloseModal"
    style="z-index: 10000"
  >
    <div>
      <v-row>
        <v-col>
          yoyo
        </v-col>
      </v-row>
      <v-row>
        <ArvaModalSaveFooter
          confirmText="Confirm Changes"
          cancelText="Cancel"
          @on-cancel="handleCloseModal"
          @on-confirm="generateLOI"
        />
      </v-row>
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "ClientProfileModificationModal",
  props: ["data"],
  components: { ArvaModal, ArvaModalSaveFooter },
  emits: ['close-modal'],
  data() {
    return {

    }
  },
  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    }
  },
}
</script>

<style scoped>
</style>
