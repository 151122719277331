<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />

    <ManageGroupSidebar
      :visible="showMangementSidebar"
      :entity="entityData"
      :fields="fieldsInGroup"
      :allFieldSupplySummaries="allFieldSupplySummaries"
      :allClientProfiles="allClientProfiles"
      @closeSidebar="handleManagementSidebarClose"
      @openLOIModal="handleLOIModalOpen"
      @renderGroupData="handleUpdateGroupData"
    />

    <LetterOfIntentModal
      v-if="openLOIModal"
      :data="dataForLOIModal"
      @close-modal="handleLOIModalClose"
      @generate-loi="generateLOI"
    />

    <ClientProfileModificationModal
      v-if="openProfileModificationModal"
      @close-modal="handleLOIModalClose"
    />

    <v-progress-circular v-if="loading" indeterminate :size="48" color="#79c61c" />
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <router-link to="/contract-groups">
            <v-icon>mdi-chevron-left</v-icon>
          </router-link>
          <h1 class="mb-0 font-weight-bold">{{ groupDetailData['name'] }}</h1>
        </div>
  
        <v-btn v-b-toggle.sidebar-right :ripple="false" outlined height="44">
          Manage Group & Contracts
        </v-btn>
      </div>
  
      <v-card outlined class="px-6 py-3 mb-6">
        <v-card-title class="pa-0">Pretend it's also a nice table :)</v-card-title>
      </v-card>
  
      <v-card outlined class="px-6 py-4">
        <v-data-table
          :headers="HEADERS"
          :items="items"
          :items-per-page="10"
          show-select
          @click:row="handleClick"
        >
          <template v-slot:top="props">
            <div v-bind="props" class="d-flex justify-space-between align-center mb-4">
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">{{ items.length }} Contract Groups</v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>
              <div class="d-flex w-50 justify-end">
                <v-btn-toggle tile mandatory group class="mr-6 align-center">
                  <v-btn :ripple="false" class="my-0 mr-3" height="32px">
                    All
                  </v-btn>
                  <v-btn :ripple="false" class="my-0 mr-3" height="32px">
                    Status Filter 2
                  </v-btn>
                  <v-btn :ripple="false" class="my-0 mr-3" height="32px">
                    Status Filter 3
                  </v-btn>
                  <v-btn :ripple="false" class="my-0 mr-3" height="32px">
                    Status Filter 4
                  </v-btn>
                </v-btn-toggle>

                <v-btn disabled :ripple="false" class="mr-3" outlined height="42px">
                  <v-icon>mdi-magnify</v-icon>
                  <span class="ml-1">Search</span>
                </v-btn>
                <v-btn disabled :ripple="false" outlined height="42px">
                  <v-icon>mdi-filter-outline</v-icon>
                  <span class="ml-1">Filter</span>
                </v-btn>
              </div>
            </div>
          </template>
          
          <template v-slot:item.field="props">
            <div class="d-flex align-center px-4 py-3">
              <img class="thumbnail" :src="props.value['thumbnail']">
              <p class="mb-0 ml-4">{{ props.value['name'] }}</p>
            </div>
          </template>
          
          <template v-slot:item.owner="props">
            <div v-bind="props">
              <p class="mb-0">{{ props.value['name'] }}</p>
              <p class="mb-0">{{ props.value['email'] }}</p>
            </div>
          </template>

          <template v-slot:item.crop="props">
            <div v-bind="props">
              <p class="mb-0 capitalize">{{ props.value }}</p>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import Permissions from "@/components/permissions/Permissions"
import ManageGroupSidebar from "../components/contractGroups/ManageGroupSidebar.vue";
import LetterOfIntentModal from "../components/modals/LetterOfIntentModal.vue";
import FieldsAPI from "@/api/FieldsAPI"
import { getGroupDetail, getGroupEditingDataset } from "@/api/ContractGroupAPI"
import { mapState } from "vuex";
import ClientProfileModificationModal from "../components/modals/ClientProfileModificationModal.vue";

const HEADERS = [
  { text: "Field", value: "field" },
  { text: "Acres", value: "acres" },
  { text: "Crop", value: "crop" },
  { text: "Reg Ag Score", value: "regAgScore" },
  { text: "Enrollment Progress", value: "enrollmentProgress" }
]

export default {
  name: "ContractGroupDetail",
  components: {
    Permissions,
    ManageGroupSidebar,
    LetterOfIntentModal,
    ClientProfileModificationModal
  },
  data() {
    return {
      openProfileModificationModal: false,
      showMangementSidebar: false,
      openLOIModal: false,
      loading: true,
      error: false,
      groupDetailData: null,
      dataForLOIModal: null,
      dataForProfileModificationModal: null,
      allFieldSupplySummaries: [],
      allClientProfiles: [],
      HEADERS
    }
  },
  mounted() {
    this.getGroupData()
  },
  computed: {
    ...mapState({
      org: state => state.Organization.organization,
      year: state => state.Organization.year
    }),
    entityData() {
      if (this.groupDetailData == null || this.org == null) return null

      return {
        id: this.groupDetailData['id'],
        org: this.org['id'],
        year: this.year,
        name: this.groupDetailData['name'],
        notes: this.groupDetailData['notes'],
        cpProfiles: this.groupDetailData['cp_profiles'],
        growerProfiles: this.groupDetailData['grower_profiles'],
        generatedContracts: this.groupDetailData['generatedContracts']
      }
    },
    items() {
      if (this.groupDetailData == null) return []

      return this.groupDetailData['fieldsupply'].map(fs => (
        {
          id: fs['id'],
          field: {
            name: fs['field_name'],
            thumbnail: fs['field_thumbnail']
          },
          acres: fs['acreage'],
          crop: fs['crop'],
          regAgScore: fs['das'],
          enrollmentProgress: 0.3
        }
      ))
    },
    fieldsInGroup() {
      if (this.groupDetailData == null) return []

      return this.groupDetailData['fieldsupply'].map(fs => (
        {
          crop: fs['crop'],
          field: fs['field'],
          acreage: fs['acreage'],
          thumbnail: fs['field_thumbnail'],
        }
      ))
    }
  },
  methods: {
    handleUpdateGroupData(data) {
      this.groupDetailData = data
    },
    handleProfileModificationModalOpen() {
      this.dataForProfileModificationModal = {}
      this.openProfileModificationModal = true
    },
    handleProfileModificationModalClose() {
      this.dataForProfileModificationModal = null
      this.openProfileModificationModal = false
    },
    handleLOIModalOpen(data) {
      this.dataForLOIModal = {
        ...data,
        org: this.org
      }
      this.openLOIModal = true
    },
    handleLOIModalClose() {
      this.dataForLOIModal = null
      this.openLOIModal = false
    },
    handleManagementSidebarClose(cancelled) {
      if (!cancelled) {}

      this.showMangementSidebar = false;
    },
    handleClick(v) {
      console.log('click!', v);
    },
    async generateLOI(payload) {
      const appendedPayload = {
        ...payload,
        fss_ids: this.groupDetailData['fieldsupply'].map(({ id }) => id),
        year: this.groupDetailData['year']
      }

      this.openLOIModal = false
      this.showLoading = true
      FieldsAPI.generateLOI(appendedPayload).then(r => {
        alert('LOI Created!');
        // this.fetchClientProfile()
        // this.showLoading = false
        // this.message = `Letter of Intent for ${this.selectedClient.client.name} (${this.selectedClient.client.id}) has been generated successfully.`
        // this.showSuccessAlert = true
        // setTimeout(() => {
        //   this.showSuccessAlert = false
        // }, 10000)
      })
    },
    getGroupData() {
      this.loading = true

      if (this.org != null && this.org['id'] != null && this.year != null) {
        getGroupDetail({ org_node_id: this.org['id'], year: this.year, group_id: this.$router.currentRoute.params['id'] })
        .then(({ data }) => {
          this.groupDetailData = data

          getGroupEditingDataset({ org_node_id: this.org['id'], year: this.year, })
          .then(({ data }) => {
            this.allFieldSupplySummaries = data['fss']
            this.allClientProfiles = data['client_profiles']
              .sort((a, b) => a['poc_email'].localeCompare(b['poc_email']))
            this.loading = false
          })
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
      }
    }
  },
  watch: {
    org() {
      this.getGroupData()
    }
  }
}
</script>

<style scoped>
.v-data-table :deep(td) {
  cursor: pointer;
}
:deep(.v-data-table__wrapper) {
  height: 450px;
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #FFFFFF;
  position: sticky;
  top: 0;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.capitalize {
  text-transform: capitalize;
}
p {
  color: #000000;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.v-btn .v-btn__content > i {
  font-size: 20px;
  margin-top: 2px;
}
.v-btn-toggle > .v-btn {
  border-radius: 8px !important;
  color: #6F767E;
  background: #FCFCFC !important;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: #1A1D1F;
  background: #F1F5F9 !important;
}
.v-card__title {
  color: #20292F;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6B7280;
  line-height: 20px;
}
:deep(.v-simple-checkbox .v-input--selection-controls__input) {
  margin-right: 0 !important;
}
:deep(.v-simple-checkbox .v-input--selection-controls__ripple) {
  display: none;
}
.v-data-table :deep(tr > th:first-of-type),
.v-data-table :deep(tr > td:first-of-type) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
