<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />
    
    <v-progress-circular v-if="loading" indeterminate :size="48" color="#79c61c" />
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <h1 class="mb-0 font-weight-bold">Contract Groups</h1>
        </div>
  
        <v-btn :ripple="false" outlined height="44">
          Support
        </v-btn>
      </div>
  
      <v-card outlined class="px-6 py-3 mb-6">
        <v-card-title class="pa-0">Pretend it's a nice table :)</v-card-title>
      </v-card>
  
      <v-card outlined class="px-6 py-3">
        <v-data-table
          show-select
          :headers="headers"
          :items="items"
          :items-per-page="10"
          @click:row="handleClick"
        >
          <template v-slot:top="props">
            <div v-bind="props" class="d-flex align-center justify-space-between mb-4">
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">{{ items.length }} Contract Groups</v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>

              <div class="d-flex w-50 justify-end">
                <v-btn-toggle tile mandatory group class="mr-6 align-center">
                  <v-btn :ripple="false" class="my-0 mr-3" height="32px">
                    All
                  </v-btn>
                  <v-btn :ripple="false" class="my-0 mr-3" height="32px">
                    Status Filter 2
                  </v-btn>
                  <v-btn :ripple="false" class="my-0 mr-3" height="32px">
                    Status Filter 3
                  </v-btn>
                  <v-btn :ripple="false" class="my-0 mr-3" height="32px">
                    Status Filter 4
                  </v-btn>
                </v-btn-toggle>

                <v-btn disabled :ripple="false" class="mr-3" outlined height="42px">
                  <v-icon>mdi-magnify</v-icon>
                  <span class="ml-1">Search</span>
                </v-btn>
                <v-btn disabled :ripple="false" outlined height="42px">
                  <v-icon>mdi-filter-outline</v-icon>
                  <span class="ml-1">Filter</span>
                </v-btn>
              </div>
            </div>
          </template>
          
          <template v-slot:item.owner="props">
            <div v-bind="props">
              <div v-if="props.value['name'] != null || props.value['email'] != null">
                <p class="mb-0">{{ props.value['name'] }}</p>
                <p class="mb-0">{{ props.value['email'] }}</p>
              </div>
              <div v-else>
                <p>No Owner Specified</p>
              </div>
            </div>
          </template>
  
          <template v-slot:item.acres="props">
            <div v-bind="props">
              {{ Math.round(props.value) }}
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import Permissions from "@/components/permissions/Permissions"
import { listOverview } from "@/api/ContractGroupAPI"
import { mapState } from "vuex/dist/vuex.common.js";

export default {
  name: "ContractGroups",
  components: {
    Permissions,
  },
  data() {
    return {
      loading: true,
      error: false,
      groupOverviewData: [],
      headers: [
        {
          text: "Group Name",
          value: "name"
        },
        {
          text: "Acres",
          value: "acres"
        },
        {
          text: "Fields",
          value: "fields"
        },
        {
          text: "Contract Owner",
          value: "owner"
        },
        {
          text: "Status",
          value: "status"
        }
      ],
    }
  },
  mounted() {
    this.getGroupOverview()
  },
  computed: {
    ...mapState({
      org: state => state.Organization.organization,
      year: state => state.Organization.year
    }),
    items() {
      return this.groupOverviewData.map(g => (
        {
          id: g['id'],
          name: g['name'],
          acres: g['acres_in_group'],
          fields: g['fields_in_group'],
          owner: {
            name: 'Figure this out',
            email: 'figurethisout@figure.out'
          },
          status: 'good'
        }
      ))
    }
  },
  methods: {
    handleClick({ id }) {
      this.$router.push(`/contract-groups/${id}`);
    },
    getGroupOverview() {
      if (this.org != null && this.org['id'] != null && this.year != null) {
        listOverview({ org_node_id: this.org['id'], year: this.year })
        .then(({ data }) => {
          this.groupOverviewData = data
          this.loading = false
        })
        .catch(e => {
          this.loading = false
          this.error = e
        })
      }
    }
  },
  watch: {
    org() {
      this.getGroupOverview()
    }
  }
}
</script>

<style scoped>
.v-data-table :deep(td) {
  cursor: pointer;
}
p {
  color: #000000;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.v-btn .v-btn__content > i {
  font-size: 20px;
  margin-top: 2px;
}
.v-btn-toggle > .v-btn {
  border-radius: 8px !important;
  color: #6F767E;
  background: #FCFCFC !important;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: #1A1D1F;
  background: #F1F5F9 !important;
}
.v-card__title {
  color: #20292F;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6B7280;
  line-height: 20px;
}
:deep(.v-simple-checkbox .v-input--selection-controls__input) {
  margin-right: 0 !important;
}
:deep(.v-simple-checkbox .v-input--selection-controls__ripple) {
  display: none;
}
.v-data-table :deep(tr > th:first-of-type),
.v-data-table :deep(tr > td:first-of-type) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
