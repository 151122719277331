<template>
  <ArvaModal
    name="createNewMoveClientTaskModal"
    title="Request A New Move Client Task"
    @close-modal="handleCloseModal"
  >
    <b-alert v-model="showErrorAlert" variant="danger" dismissible>
      <span> Failed To Request A Move Client Task:</span>
      <div
        class="error-message ml-5 p-1"
        v-for="message in errorMessage"
        :key="message"
      >
        <li>{{ message }}</li>
      </div>
    </b-alert>

    <div class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
      <span class="default-input-title">Source Organization</span>
      <div class="input-group">
        <v-select
          v-model="selectedSourceOrg"
          :items="orgDropDownOptions"
          item-text="name"
          return-object
          solo
          label="Select Source Organization"
        >
          <template #item="{ item, attrs, on }">
            <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
              {{ item.name }}
            </div>
          </template>
        </v-select>
      </div>
    </div>

    <div class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
      <span class="default-input-title">Client</span>
      <div class="input-group">
        <v-select
          v-model="selectedClient"
          :items="clientDropDownOptions"
          item-text="name"
          return-object
          solo
          label="Select Client To Move"
        >
          <template #item="{ item, attrs, on }">
            <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
              {{ item.name }}
            </div>
          </template>
        </v-select>
      </div>
    </div>

    <div class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
      <span class="default-input-title">Target Corporation</span>
      <div class="input-group">
        <v-select
          v-model="selectedTargetCorp"
          :items="targetCorpOptions"
          item-text="name"
          return-object
          solo
          label="Select Target Corporation"
        >
          <template #item="{ item, attrs, on }">
            <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
              {{ item.name }}
            </div>
          </template>
        </v-select>
      </div>
    </div>

    <div class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
      <span class="default-input-title">Target Organization</span>
      <div class="input-group">
        <v-select
          v-model="selectedTargetOrg"
          :items="targetOrgDropDownOptions"
          item-text="name"
          return-object
          solo
          label="Select Target Organization"
        >
          <template #item="{ item, attrs, on }">
            <div :style="locationItemStyle(item)" v-bind="attrs" v-on="on">
              {{ item.name }}
            </div>
          </template>
        </v-select>
      </div>
    </div>

    <div class="ml-3 col-lg-11 col-md-4 col-sm-6 col-xs-12">
      <div class="input-group">
        <v-checkbox
          v-model="sendReleaseForm"
          :disabled="movingOutsideHierarchy"
          label="Send Release Form Notification"
        ></v-checkbox>
      </div>
      <p class="ml-4">
        By unchecking this box, you acknowledge and accept responsibility for
        any data privacy violations that may occur as a result of not sending
        the release form notification.
      </p>
    </div>

    <div class="row ml-3">
      <ArvaModalSaveFooter
        confirmText="Request"
        cancelText="Cancel"
        :disabled="
          selectedClient === null ||
          selectedSourceOrg === null ||
          selectedTargetOrg === null ||
          selectedTargetCorp === null
        "
        @on-cancel="handleCloseModal"
        @on-confirm="confirmChanges"
      />
    </div>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "AddNewUserModal",
  props: ["clientOrgOptions", "targetCorpOptions"],
  components: {
    ArvaModal,
    ArvaModalSaveFooter,
  },

  data() {
    return {
      selectedClient: null,
      selectedSourceOrg: null,
      selectedTargetCorp: null,
      selectedTargetOrg: null,
      loading: false,
      showErrorAlert: false,
      errorMessage: [],
      sendReleaseForm: true,
      movingOutsideHierarchy: true,
      selectedSourceOrgTree: [],
    }
  },

  computed: {
    orgDropDownOptions() {
      return this.clientOrgOptions
    },

    clientDropDownOptions() {
      if (!this.selectedSourceOrg) return []
      return this.selectedSourceOrg.clients.filter(
        client => client.organization_id === this.selectedSourceOrg.id
      )
    },

    targetOrgDropDownOptions() {
      if (!this.selectedTargetCorp) return []
      return this.selectedTargetCorp.org_nodes.filter(
        org => org.id !== this.selectedSourceOrg.id
      )
    },
  },

  watch: {
    selectedSourceOrg() {
      let withinTree = false
      this.selectedSourceOrgTree = []
      for (let i = 0; i < this.orgDropDownOptions.length; i++) {
        if (this.orgDropDownOptions[i].id === this.selectedSourceOrg.id) {
          withinTree = true
        } else if (
          withinTree &&
          this.selectedSourceOrg.depth < this.orgDropDownOptions[i].depth
        ) {
          this.selectedSourceOrgTree.push(this.orgDropDownOptions[i].id)
        } else if (
          withinTree &&
          this.selectedSourceOrg.depth >= this.orgDropDownOptions[i].depth
        ) {
          break
        }
      }
    },
    selectedTargetOrg() {
      if (this.selectedSourceOrgTree.includes(this.selectedTargetOrg.id)) {
        this.movingOutsideHierarchy = false
      } else {
        this.movingOutsideHierarchy = true
      }
      this.sendReleaseForm = true
    },
  },

  methods: {
    locationItemStyle(item) {
      if (!item.depth) return null

      return item.depth > 1
        ? { "padding-left": item.depth + 1 + ".75em" }
        : null
    },

    handleCloseModal() {
      this.$emit("close-modal")
    },

    confirmChanges() {
      const hasError = this.validateInputs()
      if (!hasError) {
        const payload = {
          client_id: this.selectedClient.id,
          source_org_id: this.selectedSourceOrg.id,
          target_org_id: this.selectedTargetOrg.id,
          send_release_form: this.sendReleaseForm,
        }
        this.$emit("save-changes", payload)
      }
    },

    // Validate all the inputs
    //  Return True if found at least 1 error
    //  Return False if found no error
    validateInputs() {
      this.resetError()

      if (!this.selectedSourceOrg)
        this.errorMessage.push("Source Organization must be selected.")
      if (!this.selectedClient)
        this.errorMessage.push("Client To Move must be selected.")
      if (!this.selectedTargetOrg)
        this.errorMessage.push("Target Organization must be selected.")

      if (this.errorMessage.length > 0) {
        this.showErrorAlert = true
        return true
      }
      return false
    },

    resetError() {
      this.showErrorAlert = false
      this.errorMessage = []
    },

    beforeMount() {},
  },
}
</script>

<style scoped>
.org-options-style {
  max-height: 200px;
  overflow-y: scroll;
}
</style>
