<template>
  <b-tr>
    <!-- start date -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(irrigation.start_date)"
    >
      <v-menu
        v-model="showStartDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="startDate"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="getAppendIconForCell(irrigation.start_date)"
                @click:append="handleClickSubmit('start_date', startDate)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="startDate"
          :min="year + '-01-01'"
          :max="year + '-12-31'"
          @input="showStartDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- end date -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(irrigation.end_date)"
    >
      <v-menu
        v-model="showEndDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="d-flex">
            <div class="date-holder">
              <v-text-field
                v-model="endDate"
                dense
                readonly
                hide-details="true"
                v-bind="attrs"
                v-on="on"
                :append-icon="getAppendIconForCell(irrigation.end_date)"
                @click:append="handleClickSubmit('end_date', endDate)"
              ></v-text-field>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="endDate"
          :min="year + '-01-01'"
          :max="year + '-12-31'"
          @input="showEndDate = false"
          scrollable
        ></v-date-picker>
      </v-menu>
    </b-td>
    <!-- method -->
    <b-td
      class="cell-table large-column"
      :class="getClassForCell(irrigation.method)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="irrigationMethodChoices"
        item-text="display_name"
        item-value="value"
        v-model="method"
        choiceName="irrigation_method"
        :append-icon="getAppendIconForCell(irrigation.method)"
        @click:append="handleClickSubmit('method', method)"
      />
    </b-td>
    <!-- method type -->
    <b-td
      class="cell-table large-column"
      :class="getClassForCell(irrigation.method_type)"
    >
      <PracticeChoiceDropdown
        clearable
        dense
        hide-details="true"
        :items="methodTypeChoices"
        item-text="display_name"
        item-value="value"
        v-model="methodType"
        choiceName="irrigation_method_type"
        :append-icon="getAppendIconForCell(irrigation.method_type)"
        @click:append="handleClickSubmit('method_type', methodType)"
      />
    </b-td>
    <!-- amount -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(irrigation.amount)"
    >
      <v-text-field
        clearable
        dense
        v-model="amount"
        hide-details="true"
        :append-icon="getAppendIconForCell(irrigation.amount)"
        @keypress="filter($event, 'amount')"
        @click:append="handleClickSubmit('amount', amount)"
      >
      </v-text-field>
    </b-td>
    <!-- units -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(irrigation.unit)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="irrigationUnitChoices"
        item-text="display_name"
        item-value="value"
        v-model="unit"
        :append-icon="getAppendIconForCell(irrigation.unit)"
        @click:append="handleClickSubmit('unit', unit)"
      />
    </b-td>
    <!-- AWD -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(irrigation.awd)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="awd"
        :append-icon="getAppendIconForCell(irrigation.awd)"
        @click:append="handleClickSubmit('awd', awd)"
      />
    </b-td>
    <!-- AWD Times -->
    <b-td
      class="cell-table medium-column"
      :class="getClassForCell(irrigation.awd_times)"
    >
      <v-text-field
        clearable
        dense
        v-model="awdTimes"
        hide-details="true"
        :append-icon="getAppendIconForCell(irrigation.awd_times)"
        @keypress="filter($event, 'awd')"
        @click:append="handleClickSubmit('awd_times', awdTimes)"
      >
      </v-text-field>
    </b-td>
    <!-- efficiency -->
    <b-td
      class="cell-table medium-column"
      :class="{
        'efficiency-column': !additionView,
        'efficiency-column-add': additionView,
        ...getClassForCell(irrigation.efficiency),
      }"
    >
      <v-text-field
        clearable
        dense
        v-model="efficiency"
        hide-details="true"
        suffix="%"
        :append-icon="getAppendIconForCell(irrigation.efficiency)"
        @keypress="filter($event, 'efficiency')"
        @click:append="handleClickSubmit('efficiency', efficiency)"
      >
      </v-text-field>
    </b-td>
    <!-- drainage -->
    <b-td
      class="cell-table large-column"
      :class="getClassForCell(irrigation.drainage)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="drainageChoices"
        item-text="display_name"
        item-value="value"
        v-model="drainage"
        :append-icon="getAppendIconForCell(irrigation.drainage)"
        @click:append="handleClickSubmit('drainage', drainage)"
      />
    </b-td>
    <!-- source of water -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(irrigation.source_of_water)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="irrigationSourceChoices"
        item-text="display_name"
        item-value="value"
        v-model="sourceOfWater"
        :append-icon="getAppendIconForCell(irrigation.source_of_water)"
        @click:append="handleClickSubmit('source_of_water', sourceOfWater)"
      />
    </b-td>
    <!-- wildlife flood -->
    <b-td
      class="cell-table marge-column"
      :class="getClassForCell(irrigation.wildlife_flood)"
    >
      <v-select
        clearable
        dense
        hide-details="true"
        :items="yesNoOptions"
        item-text="display_name"
        item-value="value"
        v-model="wildlifeFlood"
        :append-icon="getAppendIconForCell(irrigation.wildlife_flood)"
        @click:append="handleClickSubmit('wildlife_flood', wildlifeFlood)"
      />
    </b-td>
    <b-td class="cell-table black">
      <v-btn icon x-small color="white" @click="handleAdd">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
      <v-btn icon x-small color="white" @click="handleDelete">
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </b-td>
  </b-tr>
</template>
<script>
import { mapMutations, mapGetters } from "vuex"
import { YES_NO_OPTIONS } from "@/constants"
import { IRRIGATION_SOURCE_CHOICES } from "@/constants/defaults"
import { Defaults } from "@/store/modules"
import { getAppendIconForCell, getClassForCell } from "../utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"


export default {
  name: "IrrigationRow",
  props: {
    additionView: { type: Boolean, default: false },
    cropId: { type: Number },
    field: { type: String },
    irrigation: { type: Object },
    rowIndex: { type: Number },
    year: { type: String },
  },
  components: {
    PracticeChoiceDropdown,
  },
  data() {
    return {
      yesNoOptions: YES_NO_OPTIONS,
      irrigationSourceChoices: IRRIGATION_SOURCE_CHOICES,
      showDate: false,
      showStartDate: false,
      showEndDate: false,
      getClassForCell,
      getAppendIconForCell
    }
  },
  computed: {
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    drainageChoices() {
      return this.practiceChoices["drainage_method"]
    },
    irrigationMethodChoices() {
      return this.practiceChoices["irrigation_method"]
    },
    methodTypeChoices() {
      return this.practiceChoices["irrigation_method_type"]
    },
    irrigationUnitChoices() {
      return this.practiceChoices["irrigation_units"]
    },
    crop: {
      get() {
        return this.irrigation.crop_id.value
      },
      set(val) {
        this.irrigation.crop_id.value = val
        this.irrigation.crop_id.source = "User Input"
      },
    },
    startDate: {
      get() {
        return this.irrigation.start_date.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "start_date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    endDate: {
      get() {
        return this.irrigation.end_date.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "end_date",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    amount: {
      get() {
        return this.irrigation.amount.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "irrigations",
            dataSetting: "amount",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    // date: {
    //   get() {
    //     const dateReturn = this.irrigation.date.value
    //       ? this.irrigation.date.value
    //       : `${this.year}-01-01`
    //     return dateReturn
    //   },
    //   set(val) {
    //     // fixes time zone issue
    //     let initDate = new Date(val)
    //     let userTimezoneOffset = initDate.getTimezoneOffset() * 60000
    //     let correctDate = new Date(initDate.getTime() - userTimezoneOffset)
    //     let databaseDate = new Date(correctDate).toISOString().split("T")[0]

    //     this.irrigation.date.value = databaseDate
    //     this.irrigation.date.source = "User Input"
    //   },
    // },
    efficiency: {
      get() {
        return this.irrigation.efficiency.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "irrigations",
            dataSetting: "efficiency",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    method: {
      get() {
        return this.irrigation.method.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "method",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    methodType: {
      get() {
        return this.irrigation.method_type.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "method_type",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    unit: {
      get() {
        return this.irrigation.unit.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "unit",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    awd: {
      get() {
        return this.irrigation.awd.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "awd",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    awdTimes: {
      get() {
        return this.irrigation.awd_times.value
      },
      set(val) {
        if (val === "") {
          val = null
        }
        let validVal = this.handleValidateValue(val, "number")
        if (validVal) {
          const updatePayload = {
            fieldId: this.field,
            year: this.year,
            crop: this.cropId,
            dataCategory: "irrigations",
            dataSetting: "awd_times",
            value: val,
            rowIndex: this.rowIndex,
          }
          this.spreadsheetMutate(updatePayload)
        }
      },
    },
    drainage: {
      get() {
        return this.irrigation.drainage.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "drainage",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    sourceOfWater: {
      get() {
        return this.irrigation.source_of_water.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "source_of_water",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
    wildlifeFlood: {
      get() {
        return this.irrigation.wildlife_flood.value
      },
      set(val) {
        const updatePayload = {
          fieldId: this.field,
          year: this.year,
          crop: this.cropId,
          dataCategory: "irrigations",
          dataSetting: "wildlife_flood",
          value: val,
          rowIndex: this.rowIndex,
        }
        this.spreadsheetMutate(updatePayload)
      },
    },
  },
  methods: {
    ...mapMutations({
      addFieldSettingRow: Defaults.Mutations.addFieldSettingRow,
      deleteNewFieldSetting: Defaults.Mutations.deleteNewFieldSetting,
      updateNewFieldSetting: Defaults.Mutations.updateNewFieldSetting,
      spreadsheetMutate: Defaults.Mutations.spreadsheetMutate,
    }),
    handleClickSubmit(setting, val) {
      this[setting] = val
      // this.updateNewFieldSetting({
      //   fieldId: this.field,
      //   cropId: this.cropId,
      //   dataCategory: "irrigations",
      //   dataSetting: setting,
      //   rowIndex: this.rowIndex,
      //   value: val,
      //   year: this.year,
      // })
    },
    handleCopy() {
      this.$emit("handle-copy", this.irrigation)
    },
    handleAdd() {
      const payloadObj = {
        amount: { source: "User Input", value: null },
        awd: { source: "User Input", value: null },
        awd_times: { source: "User Input", value: null },
        crop_id: { source: "User Input", value: this.cropId },
        drainage: { source: "User Input", value: null },
        efficiency: { source: "User Input", value: null },
        end_date: { source: "User Input", value: null },
        method: { source: "User Input", value: null },
        method_type: { source: "User Input", value: null },
        source_of_water: { source: "User Input", value: null },
        start_date: { source: "User Input", value: null },
        unit: { source: "User Input", value: null },
        wildlife_flood: { source: "User Input", value: null },
      }
      this.addFieldSettingRow({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "irrigations",
        dataObj: payloadObj,
        year: this.year,
      })
    },
    handleDelete() {
      var defaultObj = {}
      for (const [key, value] of Object.entries(this.irrigation)) {
        if (key === "crop_id") defaultObj[key] = value
        else defaultObj[key] = { source: "User Input", value: null }
      }

      // this.$emit("handle-delete", this.grazing)
      this.deleteNewFieldSetting({
        fieldId: this.field,
        cropId: this.cropId,
        dataCategory: "irrigations",
        defaultObj: defaultObj,
        rowIndex: this.rowIndex,
        year: this.year,
      })
    },
    handleValidateValue(val, type, length = 24, range = null) {
      function isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!
        return (
          !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        ) // ...and ensure strings of whitespace fail
      }

      if (val === null) {
        return true
      }

      if (val.length > length) {
        this.$emit(
          "bad-input",
          "Inputted value length was too long (24 characters allowed)."
        )
        return false
      }

      if (type == "number") {
        if (isNumeric(val)) {
          if (range !== null) {
            if (val >= range[0] && val <= range[1]) {
              return true
            } else {
              this.$emit(
                "bad-input",
                `Look out! Only a value between ${range[0]} and ${range[1]} can be entered here.`
              )
              return false
            }
          }
          return true
        } else {
          this.$emit(
            "bad-input",
            "Look out! A numerical value is expected here."
          )
          return false
        }
      } else if (type == "string") {
        return true
      }
    },
    filter(event, fieldInput) {
      event = event ? event : window.event
      let expect = event.target.value.toString() + event.key.toString()

      let range = null
      let inputType = "number"
      if (fieldInput === "awd") range = [0, 10]
      else if (fieldInput === "efficiency") range = [0, 100]

      let validVal = this.handleValidateValue(expect, inputType, 24, range)
      if (!validVal) event.preventDefault()
    },
  },
}
</script>
<style scoped>
.arva-alert {
  background-color: #f7c0be;
}

.arva-generated {
  background-color: #fedb80;
}

.pre-gen {
  background-color: #fedb80;
}

.bg-white {
  color: white;
}

.cell-table {
  padding: 2px 6px;
}

.cell-text {
  font-weight: 600;
  color: white;
}

.machine {
  background-color: #c0dce2;
}
.complete {
  background-color: #c7e3c1;
}

.copy-icon {
  cursor: pointer;
  color: #0f9aee;
}

.input-check {
  cursor: pointer;
}

.select-check {
  margin-top: 14px;
  cursor: pointer;
}

.submit-icon {
  color: #c7e3c1;
}

.delete {
  color: #cc0101;
  cursor: pointer;
}

.medium-column {
  width: 140px;
}

.large-column {
  width: 240px;
}

.marge-column {
  width: 180px;
}

.copy-col {
  min-width: 44px;
}

.del-col {
  min-width: 40px;
}

.v-input {
  font-size: 18px;
}
</style>
