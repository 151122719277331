<template>
  <ArvaModal
    name="editFieldAssignmentModal"
    title="Edit Field Assignment"
    :wide="true"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <form @submit.prevent="handleSubmit">
        <div>
          <v-card class="pa-4">
            <!--<v-chip color="green lighten-1 mb-2" v-if="updateOrderID">
              {{ updateOrderName }} - {{ updateOrderID }}
            </v-chip>-->
            <v-expansion-panels focusable v-model="panels">
              <v-expansion-panel v-if="fieldSupplyEligible.length > 0">
                <v-expansion-panel-header
                  >Additional Fields Eligible For this
                  Program</v-expansion-panel-header
                >
                <!--<h4>Requested Order Filters:</h4>-->
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="eligibleSearch"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                  <v-data-table
                    v-model="selectedToInclude"
                    :headers="supplyHeaders"
                    :items="fieldSupplyEligible"
                    :search="eligibleSearch"
                    show-select
                    item-key="fss_id"
                  ></v-data-table>
                  <v-btn v-if="selectedToInclude.length > 0" @click="addFields"
                    >Add Selected {{ selectedToInclude.length }} Field(s) to
                    this Program?</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="fieldSupply.length > 0">
                <v-expansion-panel-header
                  >Fields Currently Approved In
                  Program</v-expansion-panel-header
                >
                <!--<h4>Requested Order Filters:</h4>-->
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="includedSearch"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                  <v-data-table
                    v-model="selectedToRemove"
                    :headers="supplyHeaders"
                    :items="fieldSupply"
                    :search="includedSearch"
                    :single-select="false"
                    show-select
                    item-key="fss_id"
                  ></v-data-table>
                  <v-btn
                    v-if="selectedToRemove.length > 0"
                    @click="removeFields"
                    >Remove Selected {{ selectedToRemove.length }} Field(s) From
                    Program</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <h5 v-if="fieldSupplyEligible.length < 1 && fieldSupply.length < 1">
              No Fields Currently Available to Add or Remove
            </h5>
          </v-card>

          <ArvaModalSaveFooter
            confirmText="Return"
            cancelText="Cancel"
            @on-cancel="handleCloseModal"
            @on-confirm="handleSubmit"
          />
        </div>
      </form>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "EditFieldAssignmentModal",
  props: ["updateOrderID", "updateOrderName"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      panels: 0,
      previewDataFrame: null,
      orderName: null,
      showError: false,
      orderPrice: 15,
      orderGeoids: [],
      supplyTarget: 10000,
      errorText: "Please Provide an Unique Order Name",
      aggregationMetric: "acreage",
      aggregationMetrics: ["acreage", "harvest_amount", "scope3_emission"],
      fieldSupplyEligible: [],
      fieldSupply: [],
      selectedToRemove: [],
      selectedToInclude: [],
      includedSearch: "",
      eligibleSearch: "",
      supplyHeaders: [
        { text: "Field ID", value: "field_id" },
        { text: "Field", value: "field" },
        { text: "Client", value: "client" },
        //{ text: "LOI", value: "loi_entity" },
        { text: "Crop", value: "crop" },
        //{ text: "Enroll ID", value: "enrollment_id" },
      ],
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      this.$emit("close-modal")
    },

    removeFields() {
      let payload = {
        fieldSupplyIds: this.selectedToRemove.map(e => e.fss_id),
        order_id: this.updateOrderID,
        job_type: "remove",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },
    addFields() {
      this.showError = false
      let payload = {
        fieldSupplyIds: this.selectedToInclude.map(e => e.fss_id),
        order_id: this.updateOrderID,
        job_type: "add",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },
  },
  mounted() {
    if (this.updateOrderID != null) {
      CarbonReadyAPI.getEAOrderFSS({ order_id: this.updateOrderID }).then(
        response => {
          this.fieldSupplyList = response.data
          this.fieldSupplyList.forEach(fss => {
            //let loi_name = enroll["loi_entity_name"]
            //enroll["fieldsupply"].forEach(fss => {
            let fss_dict = {
              field: fss["fieldcrop"]["field_name"],
              field_id: fss["fieldcrop"]["field_id"],
              client: fss["client"],
              crop: fss["fieldcrop"]["crop_name"],
              year: fss["fieldcrop"]["year"],
              fss_id: fss["id"],
              //loi_entity: loi_name,
              //enrollment_id: enroll["id"],
            }
            if (fss["matched_program"] == null) {
              this.fieldSupplyEligible.push(fss_dict)
            } else if (fss["matched_program"] == this.updateOrderID) {
              this.fieldSupply.push(fss_dict)
            }
          })
        }
      )
    }
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
