<template>
  <b-sidebar id="sidebar-task-detail-view" width="576px" :title="task ? task['title'] : null" right shadow backdrop v-model="show">
    <div v-if="loading['state'] == true">
      <div class="sidebar-loading-wrapper" :class="loading['action'] == LOADER_ERROR ? 'error-sizing' : ''">
        <v-progress-circular v-if="loading['action'] == LOADER_PROCESSING" :size="48" color="#79c61c"
          class="sidebar-progress" indeterminate />
        <v-icon v-else-if="loading['action'] == LOADER_SUCCESS" :size="48" color="#79c61c" dark>mdi-check-bold</v-icon>
        <v-icon v-else-if="loading['action'] == LOADER_ERROR" :size="48"
          color="#ff3c7e">mdi-alert-circle-outline</v-icon>

        <div class="sidebar-error-message" v-if="loading['action'] == LOADER_ERROR">
          <p class="text-danger">Something went wrong...</p>
          <p>The request could not be processed ({{ loadingErrorMsg }}).</p>
          <v-btn @click="resetLoadingState()">close</v-btn>
        </div>
      </div>
      <div class="progress-overlay" />
    </div>

    <div class="notifications-sidebar-body">
      <p>
        <strong>{{ moveClientActions.length == 0 ? null : moveClientActions[0]["client"]["name"] }}</strong>,
        a client affiliated with the "
        <strong>{{ moveClientActions.length == 0 ? null : moveClientActions[0]["source_org"]["name"] }}</strong>"
        Organization, is the subject of a transfer request to the "
        <strong>{{ moveClientActions.length == 0 ? null : moveClientActions[0]["destination_org"]["name"] }}</strong>" Organization.
        To initiate this process, {{ initiationText }}
        <strong>review, sign, and return the Release Form to Arva.</strong>
      </p>

      <div class="existing-uploads-viewer" v-if="allReleaseFormUploads.length > 0">
        <p>Files already uploaded:</p>
        <div v-for="uploadObj, uIdx in allReleaseFormUploads" :key="uIdx + '-' + uploadObj['name']">
          <p>{{ uploadObj['name'] }} - {{ new Date(uploadObj['created_at']).toLocaleString() }}</p>
        </div>
      </div>

      <div class="notification-btn-links">
        <p>You can find the Release Form from the Download button below</p>
        <v-btn height="44px" outlined @click="downloadReleaseForm">Download Release Form</v-btn>
      </div>

      <div v-for="move_client_action in moveClientActions" :key="move_client_action.id">
        <div class="notification-btn-links upload-section">
          <p>{{ getUploadText(move_client_action) }}</p>
          <v-file-input :disabled="isTaskComplete" class="upload-input" v-model="fileMap[move_client_action.id]"
            label="Attach Files" outlined />

          <div class="footer-errors-and-btns">
            <v-btn class="mr-3" outlined height="44px" @click="show=false">Close</v-btn>
            <v-btn :disabled="!fileMap[move_client_action.id]" outlined height="44px"
              @click="uploadReleaseForm(move_client_action.id)">Upload</v-btn>
          </div>
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import moment from "moment"
import JSZip from "jszip"
import {
  BLANK_TASK_SIDEBAR,
  LOADER_PROCESSING,
  LOADER_SUCCESS,
  LOADER_ERROR,
} from "@/constants/arvaNotifications"
import { Uploads } from "@/store/modules"
import { mapActions, mapState } from "vuex"
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults"

export default {
  name: "NotificationTaskDetailSidebar",
  props: {
    visible: { required: true },
    task: { required: true }
  },
  emits: ["sidebarClosed"],
  data() {
    return {
      fileMap: {},
      show: false,
      loadingErrorMsg: "",
      loading: {
        state: false,
        action: LOADER_PROCESSING,
      },
      LOADER_ERROR,
      LOADER_PROCESSING,
      LOADER_SUCCESS,
      EVIDENCING_TYPE_CHOICES,
    }
  },
  computed: {
    ...mapState({
      organization: state => state.Organization.organization,
      user: state => state.User.user,
    }),
    allReleaseFormUploads() {
      const uploads = [];

      for (const action of this.moveClientActions) {
        uploads.push(...action['release_form']);
      }

      return uploads;
    },
    isTaskComplete() {
      return this.task != null ? this.task["status"] === 1 : false
    },
    initiationText() {
      const modelRelated = this.moveClientActions
      if (modelRelated.length > 1) {
        return "both the grower and the prospective Channel Partner are required to"
      } else if (modelRelated.length === 1) {
        const type = modelRelated[0]?.type
        if (type === "GROWER") {
          return "the grower is required to"
        } else if (type === "CHANNEL_PARTNER") {
          return "the Channel Partner is required to"
        }
      }
      return ""
    },
    moveClientActions() {
      if (this.task != null) return this.task['data']['model_related'];
      return []
    },
  },
  methods: {
    ...mapActions({
      createUpload: Uploads.Actions.create,
    }),
    getUploadText(moveClientAction) {
      if (moveClientAction?.type === "GROWER") {
        return "Please upload the signed Release Form for the Grower..."
      } else if (moveClientAction?.type === "CHANNEL_PARTNER") {
        return "Please upload the signed Release Form for the Channel Partner..."
      }
      return "Please upload the signed Release Form..."
    },
    removeSidebar(refresh=false) {
      this.$emit("sidebarClosed", refresh)
      this.resetLoadingState()
      this.fileMap = {}
    },
    resetLoadingState() {
      this.loading["state"] = false
      this.loading["action"] = LOADER_PROCESSING
      this.loadingErrorMsg = ""
    },
    downloadReleaseForm() {
      const link = document.createElement("a")
      link.href =
        "https://portal-uploads.cropforce.com/forms/Transfer_Request_Release_of_Liability_form.pdf"
      link.setAttribute("download", "ReleaseForm.pdf")
      document.body.appendChild(link)
      link.click()
    },
    async uploadReleaseForm(moveClientActionId) {
      const action = this.moveClientActions[0];

      if (action != null) {
        this.loading["state"] = true
        const uploadedFiles = this.fileMap[moveClientActionId]
        if (uploadedFiles) {
          const uploadSpec = {
            datasetType: "move-client",
            orgId: this.organization.id,
            specs: {
              userId: this.user["id"],
              moveClientActionId: moveClientActionId,
              clientId: action["client"]["id"],
              destinationOrgId:
                action["destination_org"][
                "id"
              ],
            },
          }
  
          const zip = new JSZip()
          zip.file(uploadedFiles.name, await uploadedFiles.arrayBuffer())
          const result = await zip.generateAsync({ type: "blob" })
          const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss")
          const newZipName = `${uploadSpec.specs.clientId}-move-client-${datetimeStr}.zip`
          uploadSpec["file"] = new File([result], newZipName)
  
          this.createUpload(uploadSpec)
            .then(_ => {
              this.loading["action"] = LOADER_SUCCESS
              new Promise(resolve => setTimeout(() => resolve(), 500)).then(
                () => {
                  this.removeSidebar(true)
                }
              )
            })
            .catch(err => {
              this.loading["action"] = LOADER_ERROR
              this.loadingErrorMsg = `Server Error: ${err}`
            })
        }
      }
    },
  },
  watch: {
    visible(curr) {
      this.show = curr;
    },
    show(curr) {
      if (!curr) this.removeSidebar()
    }
  },
}
</script>

<style scoped>
p {
  color: #000000;
}

.sidebar-loading-wrapper {
  position: absolute;
  top: calc(50% - 48px);
  left: calc(50% - 48px);
  width: 96px;
  background-color: white;
  padding: 24px;
  z-index: 10;
  border-radius: 8px;
}

.sidebar-loading-wrapper.error-sizing {
  width: 250px;
  text-align: center;
  left: calc(50% - 125px);
}

.progress-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 9;
}

.notifications-sidebar-body {
  padding: 24px 36px;
}

.whitespace-pre-instructions {
  white-space: pre;
}

button:deep(.v-ripple__container) {
  display: none !important;
}

.notification-btn-links {
  margin-top: 24px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background: #ffffff;
}

.notification-btn-links p {
  width: 100%;
}

.notification-btn-links>a:not(:last-child),
.notification-btn-links>button:not(:last-child) {
  margin: 0 0 12px 0;
}

.footer-errors-and-btns {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.existing-uploads-viewer {
  padding: 24px;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.existing-uploads-viewer > p {
  margin: 0 0 8px 0;
  font-weight: bold;
}
.existing-uploads-viewer > div p:last-of-type {
  margin-bottom: 0;
}
</style>