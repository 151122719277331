<template>
  <tr :class="showColorText()">
    <td>{{ job.created_at }}</td>
    <td>{{ job.client.name }}</td>
    <td>{{ job.source_org ? job.source_org.name : null }}</td>
    <td>{{ job.dest_org ? job.dest_org.name : null }}</td>
    <td>{{ job.requested_by.email }}</td>
    <td>
      <span v-if="job.sent_release_form" class="icon-check"></span>
      <span v-else class="icon-x"></span>
    </td>
    <td>{{ job.status.toUpperCase() }}</td>
  </tr>
</template>

<script>
export default {
  name: "MoveClientJobRow",

  props: ["job"],

  methods: {
    showColorText() {
      if (this.job.status === "completed") return "showCompletedText"
      else if (this.job.status === "requested") return "showRequestedText"
      else if (this.job.status === "scheduled") return "showScheduledText"
      else if (this.job.status === "rejected") return "showRejectedText"
      else return "showAwaitingApprovalText"
    },
  },
}
</script>

<style scoped>
.showCompletedText {
  color: green;
}

.showFailedText {
  color: red;
}

.showRequestedText {
  color: rgb(159, 159, 54);
}

.showScheduledText {
  color: orange;
}

.showAwaitingApprovalText {
  color: blue;
}

.showRejectedText {
  color: red;
}

.icon-check::before {
  content: "\2714";
}

.icon-x::before {
  content: "\2716";
}
</style>
